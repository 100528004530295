import { FC } from 'react'

import { Icon, Typography } from '@/components/ui'

interface IProps {
	icon: string
	text: string
	subtext?: string
	onClick: () => void
	disabled: boolean
}

const Item: FC<IProps> = ({ icon, text, onClick, disabled, subtext }) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className='flex items-center gap-4 px-4 py-2'
		>
			<Icon size={32} icon={icon} />
			<Typography variant='text'>{text}</Typography>
			<Typography variant='text-small' className='ml-auto text-dark-gray'>
				{subtext}
			</Typography>
		</button>
	)
}

export default Item
