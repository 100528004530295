import { Loading } from '@/components/screens/unauthorized/subscription-wait'

import { IRoute } from '../navigation.types'

export const subscriptionWaitRoutes: IRoute[] = [
	{
		path: '/loading',
		component: Loading
	}
]

export default subscriptionWaitRoutes
