export const DISPLAY_TEXT = {
	cameraMinSpecificationsHeadingText:
		'Камера не соответствует минимальным требованиям',
	cameraMinSpecificationsMessageText:
		'Камера должна поддерживать разрешение не менее 320*240 и 15 кадров в секунду.',
	cameraNotFoundHeadingText: 'Камера недоступна.',
	cameraNotFoundMessageText:
		'Проверь, что камера не используется другим приложением и имеет доступ к Telegram. Возможно, тебе нужно зайти в настройки, чтобы разрешить доступ к камере, и затем повторить проверку.',
	a11yVideoLabelText: 'Камера для проверки активности',
	cancelLivenessCheckText: 'Отменить проверку активности',
	goodFitCaptionText: 'Лицо хорошо вписывается',
	goodFitAltText: 'Иллюстрация лица, идеально помещающегося в овал.',
	hintCenterFaceText: 'Помести лицо по центру',
	hintCenterFaceInstructionText:
		'Инструкция: Перед началом проверки убедись, что камера включена, а лицо находится в центре экрана. Когда начнется проверка, в центре появится овал. Тебя попросят подвинуть лицо вперед в овал, затем оставаться неподвижной. Спустя несколько секунд проверка будет завершена.',
	hintFaceOffCenterText: 'Лицо не в овале, помести лицо в центр камеры.',
	hintMoveFaceFrontOfCameraText: 'Помести лицо перед камерой',
	hintTooManyFacesText: 'Убедись, что перед камерой находится только одно лицо',
	hintFaceDetectedText: 'Обнаружено лицо',
	hintCanNotIdentifyText: 'Помести лицо перед камерой',
	hintTooCloseText: 'Слишком близко',
	hintTooFarText: 'Приблизь лицо',
	hintConnectingText: 'Соединение...',
	hintVerifyingText: 'Проверяем...',
	hintCheckCompleteText: 'Проверка завершена',
	hintIlluminationTooBrightText: 'Перейди в более темное место',
	hintIlluminationTooDarkText: 'Перейди в более освещенное место',
	hintIlluminationNormalText: 'Освещение нормальное',
	hintHoldFaceForFreshnessText: 'Сохраняй неподвижность',
	hintMatchIndicatorText: 'Выполнено на 50%. Продолжай приближать лицо.',
	photosensitivityWarningBodyText:
		'Во время проверки используются вспышки разных цветов. Если ты светочувствительна, будь осторожна.',
	photosensitivityWarningHeadingText: 'Предупреждение о светочувствительности',
	photosensitivityWarningInfoText:
		'Некоторые люди могут испытывать эпилептические приступы при воздействии цветных вспышек. Если у тебя или в твоей семье есть склонность к эпилепсии, будь осторожна.',
	photosensitivityWarningLabelText: 'Подробнее о светочувствительности',
	photosensitivyWarningBodyText:
		'Во время проверки используются вспышки разных цветов. Если ты светочувствительна, будь осторожна.',
	photosensitivyWarningHeadingText: 'Предупреждение о светочувствительности',
	photosensitivyWarningInfoText:
		'У некоторых людей могут возникнуть эпилептические припадки при воздействии цветного света. Будьте осторожны, если у вас или у кого-либо из членов вашей семьи есть эпилептическое заболевание.',
	photosensitivyWarningLabelText: 'Подробнее о светочувствительности',
	retryCameraPermissionsText: 'Повторить',
	recordingIndicatorText: 'Запись',
	startScreenBeginCheckText: 'Начать видеопроверку',
	tooFarCaptionText: 'Слишком далеко',
	tooFarAltText:
		'Иллюстрация лица внутри овала; между периметром лица и границами овала есть промежуток.',
	waitingCameraPermissionText: 'Ожидается доступ к камере.'

	/*errorLabelText: '1',
    connectionTimeoutHeaderText: '1',
    connectionTimeoutMessageText: '1',
    timeoutHeaderText: '1',
    timeoutMessageText: '1',
    faceDistanceHeaderText: '1',
    faceDistanceMessageText: '1',
    multipleFacesHeaderText: '1',
    multipleFacesMessageText: '1',
    clientHeaderText: '1',
    clientMessageText: '1',
    serverHeaderText: '1',
    serverMessageText: '1',
    landscapeHeaderText: '1',
    landscapeMessageText: '1',
    portraitMessageText: '1',
    tryAgainText: '1'*/
}

export const ERROR = {
	CONNECTION_TIMEOUT:
		'Не удалось завершить проверку из-за долгого ожидания соединения. Попробуй еще раз, следуя подсказкам на экране.',
	TIMEOUT:
		'Не удалось завершить проверку из-за долгого ожидания соединения. Попробуй еще раз, следуя подсказкам на экране.',
	RUNTIME_ERROR: 'Что-то пошло не так. Попробуй пройти проверку ещё раз.',
	FRESHNESS_TIMEOUT:
		'Не удалось завершить проверку из-за долгого ожидания. Попробуй еще раз, следуя подсказкам на экране.',
	SERVER_ERROR:
		'Что-то пошло не так при проверке. Пожалуйста, попробуй ещё раз.',
	CAMERA_ACCESS_ERROR:
		'Убедись, что ты разрешила доступ к камере. Чтобы повторить проверку закрой приложение и открой его снова.',
	CAMERA_FRAMERATE_ERROR:
		'Для прохождения проверки необходима более современная камера. Попробуй использовать другое устройство — твой прогресс сохранится.',
	FACE_DISTANCE_ERROR:
		'Во время проверки избегай резких движений камеры. Попробуй пройти проверку еще раз, следуя подсказкам на экране.',
	MOBILE_LANDSCAPE_ERROR:
		'Во время проверки устройство нужно держать вертикально. Убедись, что устройство находится в вертикальном положении и попробуй пройти проверку еще раз, следуя подсказкам на экране.',
	MULTIPLE_FACES_ERROR:
		'Убедись, что во время проверки перед камерой только одно лицо и попробуй пройти проверку еще раз, следуя подсказкам на экране.',
	liveness_failed:
		'Не удалось подтвердить, что на видео — реальный человек. Убедись, что лицо четко видно, и попробуй снова.',
	image_face_compare_error:
		'Лицо на видео и всех фото должно совпадать. Пожалуйста, загрузи только свои фото.',
	male_user_flow:
		'Система распознала на видео мужчину. Frienda доступна только для девушек. Нажми “Лист ожидания”, если интересно  приложение для мужчин.',
	liveness_ref_image_upload_error:
		'Возникла внутренняя ошибка. Попробуй пройти проверку ещё раз.'
}
