import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ButtonLayout, Field, Typography } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { countCharacters } from '@/utils/countCharacters'
import { EMOJI_REGEX } from '@/utils/regex'

const EditProfile: FC = () => {
	const navigate = useNavigate()
	const { event, getTimeSpent } = useAmplitude()
	const profile = useAtomValue(profileAtom)
	const {
		uploadProfile,
		isUploadProfileLoading,
		isUploadProfileError,
		isUploadProfileSuccess
	} = useProfile()
	const { tg } = useTelegram()

	const { control, watch, setValue, setError, clearErrors } = useForm<{
		emoji: string
	}>({
		mode: 'onChange',
		defaultValues: {
			emoji: profile?.emoji
		}
	})
	const { emoji } = watch()

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.BackButton.onClick(() => navigate('/settings'))

		return () => {
			tg.MainButton.hide()
		}
	}, [])

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_visible: true,
			is_active: false,
			text: 'Загрузка'
		})
		uploadProfile({ emoji })
	}

	useEffect(() => {
		if (isUploadProfileSuccess) {
			tg.MainButton.hide()
			tg.HapticFeedback.notificationOccurred('success')
			navigate('/settings')
			const time = getTimeSpent()
			event('emoji_entered', {
				duration: time,
				number_of_emoji: countCharacters(emoji),
				emoji: emoji,
				flow: 'settings'
			})
		}
		if (isUploadProfileError) {
			tg.MainButton.hideProgress()
			setError('emoji', {})
			tg.MainButton.setParams({
				color: '#F1BBBD',
				is_visible: true,
				is_active: false,
				text: 'Сохранить'
			})
		}
	}, [isUploadProfileLoading])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)

		const disabled = Boolean(!emoji || countCharacters(emoji) < 10)

		if (emoji === profile?.emoji) {
			tg.MainButton.setParams({
				color: '#F1BBBD',
				is_visible: true,
				is_active: false,
				text: 'Сохранить'
			})
		} else {
			tg.MainButton.setParams({
				color: disabled ? '#F1BBBD' : '#EF5652',
				is_visible: true,
				is_active: !disabled,
				text: 'Сохранить'
			})
		}

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [emoji])

	const handleEmojiChange = (value: string) => {
		if (countCharacters(value) > 20) {
			setError('emoji', {})
			tg.HapticFeedback.notificationOccurred('warning')
			setTimeout(() => {
				clearErrors('emoji')
			}, 250)
			return
		}
		if (EMOJI_REGEX.test(value)) {
			setValue('emoji', value)
		} else {
			if (countCharacters(value)) {
				setError('emoji', {})
				tg.HapticFeedback.notificationOccurred('warning')
				setTimeout(() => {
					clearErrors('emoji')
				}, 250)
			}
			const filteredValue = value.slice(0, -1)
			setValue('emoji', filteredValue)
		}
	}

	const getEmojiPlaceholder = () => {
		const keyboard =
			tg.platform === 'macos'
				? 'Открыть эмодзи: ⌃ + ⌘ + Пробел'
				: tg.platform === 'windows'
				? 'Открыть эмодзи: Win + ; или Win + .'
				: ''
		return 'Расскажи о себе от 10 до 20 эмодзи\n' + keyboard
	}

	return (
		<ButtonLayout>
			<form className='flex flex-col items-center justify-start gap-3'>
				<Typography variant='h3' className='w-full leading-[28px]'>
					Опиши себя через эмодзи
				</Typography>
				<Typography
					variant='text-mini'
					className='w-full text-black leading-[18px] tracking-[0.3px]'
				>
					Чем точнее заполнен раздел, тем лучше мы подберем подруг.
				</Typography>
				<div className='flex w-full flex-col items-center justify-center gap-1'>
					<Field
						control={control}
						typeField='textarea'
						height={90}
						name='emoji'
						className={cn(emoji && 'text-[22px] tracking-[3px] leading-[25px]')}
						type='text'
						placeholder={getEmojiPlaceholder()}
						onChange={e =>
							handleEmojiChange(e.target.value.replace(/[\r\n]+/g, ''))
						}
					/>
					<Typography
						variant='text-small'
						className='text-dark-gray leading-[18px] ml-auto pr-4'
					>
						{countCharacters(emoji)}/20
					</Typography>
				</div>
				<div className='flex flex-col gap-2 mt-2 text-dark-gray'>
					<Typography variant='text-mini' className='leading-[18px]'>
						Например:
					</Typography>
					<Typography variant='text' className='leading-[18px]'>
						Малика Николаева
					</Typography>
					<span
						className={cn(
							'tracking-[3px] leading-[28px]',
							tg.platform === 'ios' ? 'text-[28px]' : 'text-[22px]'
						)}
					>
						🇰🇿🇷🇺👨‍👩‍👧🐶📚💃🏻☀️🍒🥂🧘🏽‍♀️🏊🏻‍♀️💻🇦🇺💡❤️‍🔥💰🧿💎⚡️🚀
					</span>
				</div>
			</form>
		</ButtonLayout>
	)
}

export default EditProfile
