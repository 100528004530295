import '@aws-amplify/ui-react/styles.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import useFontFaceObserver from 'use-font-face-observer'

import { useAmplitude, useTelegram } from '@/hooks'
import Navigation from '@/navigation/Navigation'
import { InitProfileProvider } from '@/providers'

import awsexports from './aws-exports'

Amplify.configure(awsexports)

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
})

function App() {
	const isFontLoaded = useFontFaceObserver([{ family: 'AeonikPro' }])
	const { event } = useAmplitude()
	const { tg } = useTelegram()

	useEffect(() => {
		const device =
			tg.platform === 'macos' || tg.platform === 'windows' ? 'pc' : 'mobile'
		document.documentElement.classList.add(device)

		const { start_param } = tg.initDataUnsafe || {}
		const pageParams = start_param?.split('_') || []
		const sourceIndex = pageParams.indexOf('source')
		const source =
			sourceIndex !== -1 && sourceIndex + 1 < pageParams.length
				? pageParams[sourceIndex + 1]
				: null

		event('app_inited', {
			...(source && { source })
		})
	}, [])

	if (!isFontLoaded) return null

	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<InitProfileProvider>
					<Navigation />
				</InitProfileProvider>
			</BrowserRouter>
			<ToastContainer
				position='top-center'
				hideProgressBar={true}
				className='pt-5 px-4 text-[15px] font-bold'
				closeButton={false}
				newestOnTop={false}
				closeOnClick
				pauseOnFocusLoss
				draggable
				theme='light'
			/>
		</QueryClientProvider>
	)
}

export default App
