import { ThemeProvider } from '@aws-amplify/ui-react'
import {
	ErrorState,
	FaceLivenessDetector
} from '@aws-amplify/ui-react-liveness'
import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@/components/ui'

import { DISPLAY_TEXT, ERROR } from '@/constants/faceLiveness'
import { useAmplitude, useProfile, useTelegram } from '@/hooks'

type TTypeError = ErrorState | string

const FaceLivenessCheck: FC = () => {
	const sessionId = localStorage.getItem('sessionId')
	const [error, setError] = useState<TTypeError>(null)
	const { tg } = useTelegram()
	const {
		isLivenessFinishSessionLoading,
		livenessFinishSession,
		errorLivenessFinishSession,
		isLivenessFinishSessionError,
		isLivenessFinishSessionSuccess
	} = useProfile()
	const { event } = useAmplitude()
	const navigate = useNavigate()
	const { refetchInitProfile } = useProfile()
	const isPayment = JSON.parse(localStorage.getItem('isPayment') || 'false')

	useEffect(() => {
		const handleMutations = mutations => {
			mutations.forEach(mutation => {
				if (mutation.type === 'childList') {
					const parentElement = document.querySelector(
						'.amplify-flex.amplify-liveness-oval-canvas'
					)

					if (parentElement) {
						const canvas = parentElement.querySelector('canvas')
						const context = canvas.getContext('2d')
						context.fillStyle = '#F2F2F7'
					}

					const messageElement = document.querySelector(
						'.amplify-liveness-toast__message'
					)
					if (messageElement) {
						const lastDiv = messageElement.querySelector('div:last-of-type')
						const element = document.querySelector('.amplify-liveness-toast')
						if (lastDiv) {
							if (
								lastDiv.textContent === 'Сохраняй неподвижность' ||
								lastDiv.textContent === 'Слишком близко'
							) {
								element.classList.add('warning')
							} else {
								element.classList.remove('warning')
							}
						}
					}
				}
			})
		}

		const observer = new MutationObserver(handleMutations)
		const config = { childList: true, subtree: true }
		observer.observe(document.body, config)
		tg.BackButton.onClick(() => navigate('/auth/face-liveness/start'))
		tg.MainButton.hide()

		return () => observer.disconnect()
	}, [])

	const handleAnalysisComplete = () => {
		livenessFinishSession(sessionId)
		event('liveness_processed')
	}

	useEffect(() => {
		if (isLivenessFinishSessionSuccess) {
			if (isPayment) {
				refetchInitProfile()
			} else {
				navigate('/auth/face-liveness/finish')
			}
		}
		if (isLivenessFinishSessionError) {
			const error = errorLivenessFinishSession.response.data.error || ''
			event('liveness_failed', {
				reason: error
			})
			setError(error)
		}
	}, [isLivenessFinishSessionLoading])

	const onSubmit = useCallback(() => {
		switch (error) {
			case 'image_face_compare_error': {
				navigate('/auth/beautiful/photo')
				break
			}
			case 'CAMERA_ACCESS_ERROR':
			case 'CAMERA_FRAMERATE_ERROR': {
				tg.close()
				break
			}
			default: {
				navigate('/auth/face-liveness/start')
				break
			}
		}
	}, [error])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [onSubmit])

	useEffect(() => {
		if (error) {
			tg.MainButton.show()
			const buttonText = {
				image_face_compare_error: 'Обновить фото',
				CAMERA_ACCESS_ERROR: 'Закрыть',
				CAMERA_FRAMERATE_ERROR: 'Закрыть',
				default: 'Повторить проверку'
			}
			tg.MainButton.setParams({
				color: '#EF5652',
				is_active: true,
				text: buttonText[error || 'default']
			})
		}
	}, [error])

	if (error) {
		return (
			<div className='h-[100vh] w-full bg-light-gray overflow-y-scroll px-4 flex flex-col items-center justify-center gap-4'>
				<div className='flex flex-col items-center gap-2'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='113'
						height='112'
						viewBox='0 0 113 112'
						fill='none'
					>
						<g clipPath='url(#clip0_5662_7556)'>
							<path
								d='M53.5 78C68.964 78 81.5 65.464 81.5 50C81.5 34.536 68.964 22 53.5 22C38.036 22 25.5 34.536 25.5 50C25.5 65.464 38.036 78 53.5 78Z'
								fill='#FFDFD4'
							/>
							<path
								d='M84.5 51.9999C84.5 69.0497 82 91 82 91H24.5C24.5 91 20.5 69.0497 20.5 51.9999C20.5 34.95 34.9 21 52.4999 21C70.1 21 84.5 34.95 84.5 51.9999ZM26.8999 51.9999C26.8999 65.6709 38.3879 76.8001 52.4999 76.8001C66.6121 76.8001 78.1001 65.6709 78.1001 51.9999C78.1001 49.5509 77.716 47.1951 77.0441 44.994C70.8576 46.3879 64.3825 45.9613 58.4478 43.7688C52.5133 41.5763 47.3893 37.7176 43.7319 32.687C40.5959 40.0958 34.612 46.079 27.06 49.3339C26.8999 50.202 26.8999 51.1008 26.8999 51.9999Z'
								fill='#202022'
							/>
							<path
								d='M24.5208 95.0999C24.507 94.9476 24.5 94.7927 24.5 94.6352V92.644C24.5 87.3333 43.268 79.0024 43.268 79.0024C50.6921 81.9204 54.939 81.9433 62.2566 79C62.2566 79 81.5 87.1736 81.5 92.644V94.6352C81.5 94.6981 81.4988 94.7607 81.4966 94.8226C73.2728 99.9895 63.4012 103 52.7857 103C42.3614 103 32.6546 100.097 24.5208 95.0999Z'
								fill='#EF3B35'
							/>
							<path
								d='M111 76.15C105.674 76.15 100.484 74.2168 95.3999 70.3074C95.1403 70.1079 94.8245 70 94.4999 70C94.1754 70 93.8596 70.1079 93.5999 70.3074C88.516 74.2168 83.326 76.15 77.9999 76.15C77.6022 76.15 77.2207 76.3118 76.9394 76.6002C76.658 76.8885 76.5 77.2797 76.5 77.6874V88.4498C76.5 98.7017 82.4159 106.235 93.9499 110.893C94.3034 111.036 94.6966 111.036 95.0499 110.893C106.584 106.235 112.5 98.6997 112.5 88.4498V77.6874C112.5 77.2797 112.342 76.8885 112.061 76.6002C111.779 76.3118 111.398 76.15 111 76.15Z'
								fill='#F2F2F7'
							/>
							<path
								d='M94.4975 99.9947C100.019 99.9947 104.495 95.2948 104.495 89.4973C104.495 83.6998 100.019 79 94.4975 79C88.976 79 84.5 83.6998 84.5 89.4973C84.5 95.2948 88.976 99.9947 94.4975 99.9947Z'
								fill='#202022'
							/>
							<path
								d='M108.25 78.95C103.812 78.95 99.4866 77.3941 95.2499 74.2476C95.0336 74.0868 94.7704 74 94.5 74C94.2295 74 93.9663 74.0868 93.75 74.2476C89.5133 77.3941 85.1883 78.95 80.7499 78.95C80.4184 78.95 80.1005 79.0803 79.866 79.3125C79.6317 79.5445 79.5 79.8593 79.5 80.1875V88.8499C79.5 97.1014 84.43 103.165 94.0416 106.914C94.3362 107.029 94.6638 107.029 94.9583 106.914C104.57 103.165 109.5 97.0998 109.5 88.8499V80.1875C109.5 79.8593 109.368 79.5445 109.134 79.3125C108.899 79.0803 108.582 78.95 108.25 78.95Z'
								fill='#EF3B35'
							/>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M88.857 84.3844C89.3329 83.8719 90.1045 83.8719 90.5805 84.3844L95 89.1438L99.4194 84.3844C99.8954 83.8719 100.667 83.8719 101.143 84.3844C101.619 84.897 101.619 85.7281 101.143 86.2406L96.7236 91.0001L101.143 95.7595C101.619 96.272 101.619 97.103 101.143 97.6156C100.667 98.1281 99.8954 98.1281 99.4194 97.6156L95 92.8562L90.5805 97.6156C90.1045 98.1281 89.3329 98.1281 88.857 97.6156C88.381 97.103 88.381 96.272 88.857 95.7595L93.2764 91.0001L88.857 86.2406C88.381 85.7281 88.381 84.897 88.857 84.3844Z'
								fill='white'
							/>
						</g>
						<defs>
							<clipPath id='clip0_5662_7556'>
								<rect
									width='112'
									height='112'
									fill='white'
									transform='translate(0.5)'
								/>
							</clipPath>
						</defs>
					</svg>
					<Typography
						variant='h3'
						className='max-w-[260px] text-center leading-7'
					>
						Проверка не пройдена
					</Typography>
				</div>
				<Typography
					variant='text'
					className='w-full text-black text-center leading-[22px] tracking-[0.3px]'
				>
					{ERROR[error] ||
						'Что-то пошло не так при проверке. Пожалуйста, попробуй ещё раз.'}
				</Typography>
			</div>
		)
	}

	return (
		<div className='h-[100vh] w-full bg-white overflow-y-scroll flex flex-col items-center justify-center gap-6'>
			<ThemeProvider>
				<FaceLivenessDetector
					sessionId={sessionId}
					disableStartScreen={true}
					config={{
						// @ts-ignore
						systemClockOffset: 20000
					}}
					components={{
						ErrorView: () => {
							return <div></div>
						}
					}}
					displayText={DISPLAY_TEXT}
					region='us-east-1'
					onUserCancel={() => {
						navigate('/auth/face-liveness/start')
					}}
					// @ts-ignore
					onAnalysisComplete={handleAnalysisComplete}
					onError={error => {
						event('liveness_failed', {
							reason: 'error'
						})
						setError(error.state)
					}}
				/>
			</ThemeProvider>
		</div>
	)
}

export default FaceLivenessCheck
