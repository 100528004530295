import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const CardSkeleton: FC = () => {
	return (
		<div className='bg-light-gray'>
			<Skeleton
				className='absolute top-0 bottom-0 left-0 right-0'
				width={window.innerWidth}
				height={window.innerHeight}
				highlightColor='#DEDEE2'
				baseColor='#F2F2F7'
			/>
		</div>
	)
}

export default CardSkeleton
