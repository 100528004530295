import React, {
	FC,
	PropsWithChildren,
	createContext,
	useContext,
	useState
} from 'react'

interface InitProfileContextType {
	shouldRefetch: boolean
	triggerRefetch: () => void
	resetRefetch: () => void
}

const InitProfileContext = createContext<InitProfileContextType | undefined>(
	undefined
)

export const InitProfileProvider: FC<PropsWithChildren> = ({ children }) => {
	const [shouldRefetch, setShouldRefetch] = useState(false)

	const triggerRefetch = () => {
		setShouldRefetch(true)
	}

	const resetRefetch = () => {
		setShouldRefetch(false)
	}

	return (
		<InitProfileContext.Provider
			value={{ shouldRefetch, triggerRefetch, resetRefetch }}
		>
			{children}
		</InitProfileContext.Provider>
	)
}
export const useInitProfile = () => useContext(InitProfileContext)
