// @ts-nocheck
import { useAtomValue } from 'jotai/index'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@/components/ui'

import { useAmplitude, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { addParamsToCloudinaryUrl } from '@/utils/addParamsToCloudinaryUrl'

const Start: FC = () => {
	const { tg } = useTelegram()
	const { event, getTimeSpent } = useAmplitude()
	const profile = useAtomValue(profileAtom)
	const navigate = useNavigate()

	useEffect(() => {
		tg.BackButton.hide()
		tg.MainButton.show()
		tg.MainButton.hideProgress()
		tg?.setBottomBarColor('#EF3B35')
		tg.setBackgroundColor('#EF3B35')

		tg.MainButton.setParams({
			text: 'Найти подруг',
			is_active: true,
			color: '#fff',
			text_color: '#EF3B35'
		})
		tg.MainButton.onClick(() => {
			tg.HapticFeedback.notificationOccurred('success')
			navigate('/community')
			localStorage.clear()
			const time = getTimeSpent()
			event('registration_completed', {
				duration: time
			})
		})

		return () => {
			tg?.setBottomBarColor('#F2F2F7')
			tg.setBackgroundColor('#F2F2F7')
		}
	}, [])

	return (
		<div className='h-[100vh] w-full bg-red overflow-y-scroll flex flex-col items-center justify-center gap-4'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='142'
				height='38'
				viewBox='0 0 243 66'
				fill='none'
			>
				<path
					d='M3.22816 22.7981C4.21971 33.253 16.5845 36.5854 19.7795 47.4982C22.4067 56.4608 16.0082 60.8022 18.9828 63.7869C23.3219 68.1368 40.4411 62.8796 45.6616 51.458C48.8735 44.4287 46.4751 38.7815 45.6955 37.3485C42.6106 31.6504 41.7716 29.7341 32.0256 19.6523C19.1354 6.33132 12.6861 -0.324911 10.3809 0.819791C8.1605 1.9221 8.2622 9.12948 13.7115 15.1922C14.22 15.7603 14.6522 16.2182 15.076 16.6421C16.7031 18.2871 17.8557 19.2877 19.1184 20.4324C19.1184 20.4324 20.0252 21.2464 22.9152 24.07C28.1187 29.1406 31.873 33.7194 33.8985 36.348C35.5681 38.5271 36.7291 41.0031 37.1189 43.5214C37.6274 46.8368 36.5257 48.3546 35.746 48.4479C34.8477 48.5581 33.9833 48.5666 29.0255 41.5118C27.1102 38.7815 25.3135 36.8058 21.7287 32.846C19.4235 30.3022 17.8896 28.6827 16.6862 27.4532C15.7455 26.4866 14.22 24.9094 11.1775 21.806C7.60116 18.1599 5.79603 16.3369 5.00787 16.4556C3.45698 16.676 2.99086 20.1186 3.24511 22.7981H3.22816Z'
					fill='#F79D9A'
				/>
				<path
					d='M44.5262 22.7981C43.5346 33.253 31.1699 36.5854 27.9749 47.4982C25.3477 56.4608 31.7461 60.8022 28.7715 63.7869C24.4324 68.1368 7.31325 62.8796 2.09277 51.458C-1.11919 44.4287 1.27918 38.7815 2.05887 37.3485C5.1437 31.6504 5.98271 29.7341 15.7287 19.6523C28.6189 6.33132 35.0683 -0.324911 37.3734 0.819791C39.5938 1.9221 39.4921 9.12948 34.0428 15.1922C33.5343 15.7603 33.1021 16.2182 32.6784 16.6421C31.0512 18.2871 29.8986 19.2877 28.6359 20.4324C28.6359 20.4324 27.7291 21.2464 24.8392 24.07C19.6356 29.1406 15.8813 33.7194 13.8558 36.348C12.1863 38.5271 11.0252 41.0031 10.6354 43.5214C10.1269 46.8368 11.2286 48.3546 12.0083 48.4479C12.9066 48.5581 13.7711 48.5666 18.7288 41.5118C20.6441 38.7815 22.4408 36.8058 26.0256 32.846C28.3308 30.3022 29.8647 28.6827 31.0682 27.4532C32.0089 26.4866 33.5343 24.9094 36.5768 21.806C40.1532 18.1599 41.9583 16.3369 42.7465 16.4556C44.2973 16.676 44.7635 20.1186 44.5092 22.7981H44.5262Z'
					fill='white'
				/>
				<path
					d='M65.4371 23.5194C63.323 25.524 62.2637 28.2885 62.2637 31.8194L62.2078 33.196H57.2891V40.3093H62.2101V60.7687H70.8519V40.3093H78.9463V33.196H70.8519V32.2574C70.8519 30.7869 71.243 29.7008 72.0297 29.0058C72.8118 28.3086 74.0812 27.96 75.8288 27.96C76.8143 27.96 77.8513 28.0695 78.9463 28.2907V21.1215C77.525 20.7193 75.9562 20.5137 74.2443 20.5137C70.4877 20.5137 67.5556 21.5148 65.4393 23.5194H65.4371Z'
					fill='white'
				/>
				<path
					d='M93.0685 34.9307C91.5734 36.4571 90.4784 38.7835 89.7878 41.9055V33.1921H81.3672V60.7648H90.0605V51.2245C90.0605 48.0243 90.7264 45.5928 92.0561 43.919C93.3858 42.2474 95.4932 41.4116 98.3716 41.4116H99.3013C99.6298 41.4116 99.9561 41.4317 100.287 41.4674V32.754C99.8131 32.7183 99.3572 32.6803 98.9192 32.6445C96.5123 32.6445 94.5613 33.4088 93.0685 34.9307Z'
					fill='white'
				/>
				<path
					d='M111.584 20.4285C110.4 19.3826 108.913 18.8574 107.126 18.8574C105.338 18.8574 103.854 19.3804 102.667 20.4285C101.483 21.4743 100.891 22.8085 100.891 24.4265C100.891 26.0445 101.483 27.3317 102.667 28.3955C103.852 29.4614 105.338 29.9933 107.126 29.9933C108.913 29.9933 110.397 29.4614 111.584 28.3955C112.771 27.3295 113.361 26.0042 113.361 24.4265C113.361 22.8487 112.766 21.4766 111.584 20.4285Z'
					fill='white'
				/>
				<path
					d='M111.557 33.1953H102.752V60.768H111.557V33.1953Z'
					fill='white'
				/>
				<path
					d='M125.658 39.5918C126.68 38.9303 127.882 38.5973 129.268 38.5973C130.653 38.5973 131.838 38.9191 132.821 39.5627C133.806 40.2063 134.517 41.1338 134.953 42.3472H123.305C123.853 41.1718 124.635 40.251 125.656 39.5895H125.658V39.5918ZM134.49 51.8607C133.925 52.5781 133.196 53.1211 132.302 53.4876C131.408 53.8541 130.398 54.0396 129.268 54.0396C127.918 54.0396 126.733 53.7446 125.712 53.1569C124.691 52.5691 123.909 51.7043 123.361 50.5645C122.903 49.6125 122.639 48.4884 122.565 47.199H143.758C143.865 44.22 143.347 41.6388 142.2 39.451C141.052 37.2654 139.367 35.5826 137.143 34.4048C134.919 33.2294 132.329 32.6416 129.379 32.6416C126.429 32.6416 123.736 33.2495 121.423 34.4607C119.108 35.672 117.323 37.3659 116.064 39.5337C114.806 41.7036 114.196 44.1463 114.232 46.8682C114.232 49.6617 114.851 52.1624 116.091 54.3681C117.329 56.5739 119.108 58.2835 121.423 59.4969C123.739 60.7082 126.409 61.3161 129.435 61.3161C132.168 61.3161 134.631 60.8199 136.819 59.8277C139.007 58.8355 140.774 57.4365 142.124 55.6375C143.474 53.8385 144.238 51.7601 144.419 49.4069H135.507C135.4 50.3254 135.06 51.1456 134.497 51.8607H134.492H134.49Z'
					fill='white'
				/>
				<path
					d='M164.637 32.6434C162.449 32.6434 160.58 33.1507 159.032 34.1608C157.483 35.171 156.305 36.6146 155.503 38.4874L155.447 33.1932H146.754V60.7659H155.447V46.2644C155.447 44.3894 155.948 42.8921 156.951 41.768C157.952 40.6484 159.311 40.0852 161.025 40.0852C162.558 40.0852 163.74 40.5434 164.579 41.4619C165.417 42.3804 165.837 43.6497 165.837 45.2655V60.7614H174.586V43.5581C174.586 40.1769 173.692 37.5108 171.906 35.5598C170.119 33.6111 167.696 32.6367 164.634 32.6367L164.637 32.6434Z'
					fill='white'
				/>
				<path
					d='M199.004 50.5638C198.401 51.6298 197.572 52.4477 196.517 53.0176C195.46 53.5897 194.258 53.8713 192.908 53.8713C191.558 53.8713 190.41 53.5852 189.355 53.0176C188.298 52.45 187.466 51.6298 186.867 50.5638C186.264 49.4978 185.982 48.3022 186.02 46.9815C186.02 45.6585 186.311 44.4651 186.894 43.3991C187.478 42.3331 188.295 41.5063 189.357 40.9163C190.414 40.3285 191.601 40.0335 192.91 40.0335C194.22 40.0335 195.46 40.3285 196.519 40.9163C197.576 41.504 198.408 42.3331 199.007 43.3991C199.61 44.4651 199.91 45.6607 199.91 46.9815C199.91 48.3022 199.61 49.5001 199.007 50.5638H199.004ZM199.635 38.4312H199.579C198.705 36.592 197.465 35.1707 195.86 34.1561C194.256 33.146 192.378 32.6387 190.228 32.6387C187.75 32.6387 185.5 33.2622 183.475 34.5136C181.453 35.7651 179.866 37.4837 178.717 39.6693C177.568 41.8571 176.994 44.293 176.994 46.9748C176.994 49.6565 177.568 52.1505 178.717 54.3361C179.866 56.524 181.45 58.2336 183.475 59.465C185.497 60.6963 187.75 61.3131 190.228 61.3131C192.381 61.3131 194.256 60.8058 195.86 59.7957C197.465 58.7856 198.703 57.3419 199.579 55.4692H199.635V60.7634H208.384V21.0625H199.635V38.4312Z'
					fill='white'
				/>
				<path
					d='M232.952 50.5645C232.348 51.6305 231.519 52.4484 230.464 53.0183C229.407 53.5904 228.205 53.872 226.855 53.872C225.505 53.872 224.357 53.586 223.302 53.0183C222.245 52.4507 221.414 51.6305 220.815 50.5645C220.211 49.4985 219.93 48.3029 219.968 46.9822C219.968 45.6592 220.258 44.4658 220.841 43.3998C221.425 42.3338 222.243 41.507 223.304 40.917C224.361 40.3292 225.548 40.0343 226.857 40.0343C228.167 40.0343 229.407 40.3292 230.467 40.917C231.526 41.5047 232.355 42.3338 232.954 43.3998C233.557 44.4658 233.857 45.6614 233.857 46.9822C233.857 48.3029 233.557 49.5008 232.954 50.5645H232.952ZM233.582 33.1958V38.4342H233.526C232.652 36.5949 231.412 35.1736 229.807 34.159C228.203 33.1489 226.326 32.6416 224.176 32.6416C221.697 32.6416 219.445 33.2651 217.422 34.5166C215.4 35.7681 213.813 37.4866 212.664 39.6722C211.516 41.8601 210.941 44.296 210.941 46.9777C210.941 49.6595 211.516 52.1535 212.664 54.3391C213.813 56.5269 215.398 58.2365 217.422 59.4679C219.445 60.6993 221.697 61.3161 224.176 61.3161C226.328 61.3161 228.203 60.8088 229.807 59.7986C231.412 58.7885 232.65 57.3449 233.526 55.4721H233.582V60.7663H242.331V33.1981H233.582V33.1958Z'
					fill='white'
				/>
			</svg>

			<svg width='311' height='311' viewBox='0 0 311 311'>
				<pattern
					id='pattern'
					width='100%'
					height='100%'
					patternUnits='objectBoundingBox'
				>
					<rect width='311' height='311' fill='#F2F2F7' />
					<image
						href={addParamsToCloudinaryUrl(
							profile.media.beautiful.url,
							'ar_1.0,c_thumb,g_face,w_600,z_0.1'
						)}
						width='311'
						height='311'
						preserveAspectRatio='xMidYMin slice'
					></image>
				</pattern>
				<path
					d='M78.32 46.7842L104.907 11.3945C112.777 0.914072 127.093 -2.28235 138.689 3.8205L177.833 24.4524L221.644 18.2431C234.618 16.4119 247.016 24.2526 250.887 36.7744L263.969 79.0612L299.359 105.649C309.839 113.519 313.036 127.835 306.933 139.43L286.301 178.574L292.51 222.385C294.342 235.36 286.501 247.757 273.979 251.628L231.692 264.71L205.105 300.1C197.235 310.581 182.919 313.777 171.323 307.674L132.179 287.042L88.3684 293.252C75.3938 295.083 62.9966 287.242 59.1256 274.72L46.043 232.434L10.6533 205.846C0.172815 197.976 -3.02358 183.66 3.07927 172.065L23.7111 132.92L17.5018 89.1096C15.6706 76.135 23.5114 63.7378 36.0332 59.8669L78.32 46.7842Z'
					fill='url(#pattern)'
				/>
			</svg>

			<Typography
				variant='h2'
				className='leading-[34px] mt-4 text-white text-center tracking-[0.38px]'
			>
				{profile.name.split(' ')[0]}, <br /> поздравляем!
			</Typography>
			<Typography
				variant='text'
				className='leading-[22px] text-white text-center tracking-[0.2px] max-w-[325px]'
			>
				Теперь ты среди амбициозных и вдохновляющих женщин Frienda!
			</Typography>
		</div>
	)
}

export default Start
