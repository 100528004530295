import cn from 'clsx'
import { FC, PropsWithChildren } from 'react'

import { Typography } from '@/components/ui'

import { IButton } from './button.interface'

const Button: FC<PropsWithChildren<IButton>> = ({
	variant = 'default',
	disabled,
	children,
	loading,
	...props
}) => {
	return (
		<button
			className={cn(
				'rounded-[10px] py-3.5 w-full relative',
				{ 'bg-gray': variant === 'default' },
				{ 'bg-accent': variant === 'primary' },
				{ 'opacity-35': disabled }
			)}
			disabled={disabled}
			{...props}
		>
			<Typography
				variant='button'
				className={cn(
					'leading-[22px]',
					{ 'text-dark': variant === 'default' },
					{ 'text-white': variant === 'primary' }
				)}
			>
				{children}
			</Typography>
			{loading && (
				<div className='absolute right-3 transform top-[50%] translate-y-[-50%]'>
					<div className='loader --dark' />
				</div>
			)}
		</button>
	)
}

export default Button
