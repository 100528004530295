import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { CardEnd, Icon, MainLayout, Typography } from '@/components/ui'
import { ICard } from '@/components/ui/card/card.interface'

import { useAmplitude, useSearch, useTelegram } from '@/hooks'
import { addParamsToCloudinaryUrl } from '@/utils/addParamsToCloudinaryUrl'

const Friends: FC = () => {
	const { getData, isLoading, data, isIdle } = useSearch()
	const { tg } = useTelegram()
	const { event } = useAmplitude()
	const navigate = useNavigate()

	useEffect(() => {
		getData('friends')
	}, [])

	const handleClickFriend = (item: ICard) => {
		navigate('/friend', {
			state: {
				user_id: item.user_id
			}
		})
	}

	const handleClickTelegram = (e, item) => {
		e.stopPropagation()
		tg.openTelegramLink(`https://t.me/${item.telegram}`)
		event('go_to_telegram', {
			screen: 'friends',
			search_id: data.id,
			profile_id: item.user_id
		})
	}

	if (isLoading || isIdle) {
		return <MainLayout></MainLayout>
	}

	return (
		<MainLayout>
			{data?.response?.length ? (
				<div className='flex flex-col gap-2 overflow-auto h-full p-4'>
					{data.response.map(item => (
						<div
							key={item.user_id}
							className='flex items-center gap-4 justify-between p-4 bg-white rounded-xl'
							onClick={() => handleClickFriend(item)}
						>
							<div className='flex items-center gap-4 cursor-default'>
								<img
									src={addParamsToCloudinaryUrl(
										item.media.beautiful.url,
										'ar_1.0,c_thumb,g_face,w_600,z_0.1'
									)}
									alt=''
									className='w-12 h-12 object-cover rounded-full'
								/>
								<div className='flex flex-col gap-0.5'>
									<Typography
										variant='text-bold'
										className='leading-[22px] tracking-[0.2px]'
									>
										{item.name}
									</Typography>
									<Typography
										variant='text-mini'
										className='text-dark-gray leading-[18px] tracking-[0.3px]'
									>
										{item.country.flag} {item.city['ru']}
										{item?.country ? `, ${item.country['ru']}` : ''}
									</Typography>
								</div>
							</div>
							<button
								className='p-3 -m-3'
								onClick={e => handleClickTelegram(e, item)}
							>
								<Icon size={28} icon='telegram' color='#202022' />
							</button>
						</div>
					))}
				</div>
			) : (
				<CardEnd type='friends' />
			)}
		</MainLayout>
	)
}

export default Friends
