import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import 'react-loading-skeleton/dist/skeleton.css'

import App from './App'
import './index.scss'

const tg = window.Telegram.WebApp
const user = tg.initDataUnsafe.user

Sentry.init({
	environment: import.meta.env.VITE_AMPLITUDE_INSTANCE_NAME,
	dsn: 'https://fcc20fcec6e5cf3a633eaa8f65281c76@o4508149492023296.ingest.us.sentry.io/4508149493530624',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			networkDetailAllowUrls: ['https://frienda.cc/api', 'https://dev.frienda.cc/api'],
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false
		})
	],
	tracesSampleRate: 1.0,
	tracePropagationTargets: ['https://frienda.cc', 'https://dev.frienda.cc'],
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0
})

Sentry.setUser({
	id: user?.id.toString(),
	...(user?.username && { username: user.username })
})

amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY, {
	instanceName: import.meta.env.VITE_AMPLITUDE_INSTANCE_NAME,
	appVersion: '1.0.0',
	autocapture: {
		attribution: false,
		pageViews: false,
		sessions: true,
		formInteractions: false,
		fileDownloads: false,
		elementInteractions: false
	},
	sessionTimeout: 600000,
	userId: user?.id.toString()
})

const identifyEvent = new amplitude.Identify()

if (user?.username) identifyEvent.set('username', user.username)
identifyEvent.set('language_code', user?.language_code)
identifyEvent.set('is_premium', user?.is_premium)
identifyEvent.set('telegram_mini_app_api_version', tg?.version)

amplitude.identify(identifyEvent)

tg.ready()
tg.disableVerticalSwipes()
tg.setHeaderColor('#F2F2F7')
tg.setBackgroundColor('#F2F2F7')
// @ts-ignore
tg?.setBottomBarColor('#F2F2F7')

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
