import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Card, CardSkeleton, MainLayout } from '@/components/ui'

import { useProfile, useTelegram } from '@/hooks'

const Friend: FC = () => {
	const { state } = useLocation()
	const { tg } = useTelegram()
	const [isFirstCardLoading, setIsFirstCardLoading] = useState(false)
	const navigate = useNavigate()
	const { profile, isGetProfileSuccess } = useProfile(false, state.user_id)

	useEffect(() => {
		tg.BackButton.onClick(() => navigate('/friends'))
		return () => {
			tg.BackButton.offClick(() => navigate('/friends'))
		}
	}, [])

	return (
		<MainLayout>
			{!isFirstCardLoading && (
				<div className='absolute top-0 bottom-0 right-0 left-0 z-50'>
					<CardSkeleton />
				</div>
			)}
			{isGetProfileSuccess ? (
				<Card
					card={profile}
					setIsFirstCardLoading={setIsFirstCardLoading}
					isActive
				/>
			) : (
				<CardSkeleton />
			)}
		</MainLayout>
	)
}

export default Friend
