import {
	Address,
	AuthenticDescription,
	AuthenticPhoto,
	BeautifulDescription,
	BeautifulPhoto,
	Contacts,
	Date,
	Emoji,
	FaceLivenessCheck,
	FaceLivenessFinish,
	FaceLivenessStart,
	Name,
	Payment,
	ProfessionalDescription,
	ProfessionalPhoto,
	Settings,
	Welcome
} from '@/components/screens/unauthorized/registration'

import { IRoute } from '../navigation.types'

const registrationRoutes: IRoute[] = [
	{
		path: '/welcome',
		component: Welcome
	},
	{
		path: '/auth/name',
		component: Name
	},
	{
		path: '/auth/date',
		component: Date
	},
	{
		path: '/auth/address',
		component: Address
	},
	{
		path: '/auth/emoji',
		component: Emoji
	},
	{
		path: '/auth/contacts',
		component: Contacts
	},
	{
		path: '/auth/beautiful/photo',
		component: BeautifulPhoto
	},
	{
		path: '/auth/beautiful/description',
		component: BeautifulDescription
	},
	{
		path: '/auth/professional/photo',
		component: ProfessionalPhoto
	},
	{
		path: '/auth/professional/description',
		component: ProfessionalDescription
	},
	{
		path: '/auth/authentic/photo',
		component: AuthenticPhoto
	},
	{
		path: '/auth/authentic/description',
		component: AuthenticDescription
	},
	{
		path: '/auth/face-liveness/start',
		component: FaceLivenessStart
	},
	{
		path: '/auth/face-liveness/check',
		component: FaceLivenessCheck
	},
	{
		path: '/auth/face-liveness/finish',
		component: FaceLivenessFinish
	},
	{
		path: '/auth/payment',
		component: Payment
	},
	{
		path: '/settings',
		component: Settings
	}
]

export default registrationRoutes
