import cn from 'clsx'
import { FC, PropsWithChildren } from 'react'

import { config } from './typography.config'
import { ITypography } from './typography.interface'

const Typography: FC<PropsWithChildren<ITypography>> = ({
	variant,
	className,
	children,
	...props
}) => {
	const classes = cn(
		{
			[config[variant]]: variant
		},
		className
	)

	return (
		<p className={classes} {...props}>
			{children}
		</p>
	)
}

export default Typography
