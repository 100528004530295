import { useAtomValue } from 'jotai'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ButtonLayout, PaymentScreen } from '@/components/ui'

import { useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { notifyError } from '@/utils/toastNotifications'

const Payment: FC = () => {
	const { tg } = useTelegram()
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const profile = useAtomValue(profileAtom)
	const currency = profile.country.en === 'Russia' ? 'rub' : 'usd'
	const [paymentInfo, setPaymentInfo] = useState({
		id: profile.user_id,
		subscription: '4_months',
		method: ''
	})

	const onSubmit = () => {
		if (paymentInfo.id) {
			window.location.replace(
				`https://dev.frienda.cc/pay?tg_id=${paymentInfo.id}&method_type=${paymentInfo.method}&plan=${paymentInfo.subscription}`
			)
			tg.MainButton.hide()
		} else {
			console.error('User ID is undefined')
		}
	}

	useEffect(() => {
		tg.MainButton.show()
		tg.BackButton.show()
		tg.SettingsButton.show()
		tg.SettingsButton.onClick(() => navigate('/settings'))
		tg.BackButton.onClick(() => navigate('/welcome'))
		tg.MainButton.onClick(() => {
			setShowModal(true)
		})
		tg.MainButton.setParams({
			color: '#EF5652',
			is_active: true,
			text: 'Оплатить'
		})
		if (localStorage.getItem('errorPayment')) {
			notifyError(
				'К сожалению, оплата не прошла. Убедись, что у тебя достаточно средств на карте и все данные указаны верно, или попробуй выбрать другой способ оплаты.'
			)
			localStorage.removeItem('errorPayment')
		}
	}, [])

	useEffect(() => {
		if (paymentInfo.method) {
			onSubmit()
		}
	}, [paymentInfo])

	useEffect(() => {
		if (showModal) {
			tg.MainButton.hide()
		} else {
			tg.MainButton.show()
		}
	}, [showModal])

	return (
		<ButtonLayout>
			<PaymentScreen
				currency={currency}
				paymentInfo={paymentInfo}
				setPaymentInfo={setPaymentInfo}
				showModal={showModal}
				setShowModal={setShowModal}
			/>
		</ButtonLayout>
	)
}

export default Payment
