import { FC, PropsWithChildren } from 'react'

const ButtonLayout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className='h-[100vh] bg-light-gray overflow-y-scroll py-2 px-4'>
			{children}
		</div>
	)
}

export default ButtonLayout
