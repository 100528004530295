import { toast } from 'react-toastify'

import Icon from '../components/ui/icon/Icon'

export const notifyMessage = (message: string, icon: string) => {
	toast.success(message, {
		autoClose: 3000,
		className: 'text-dark rounded-[12px]',
		icon: () => {
			return <Icon icon={icon} size={24} color='#202022' />
		}
	})
}

export const notifyWarning = (message: string) => {
	toast.warning(message, {
		autoClose: 3000,
		className: 'text-dark rounded-[12px]',
		icon: () => {
			return <Icon icon='warning' size={24} color='#EF3B35' />
		}
	})
}

export const notifyError = (message: string) => {
	toast.error(message, {
		autoClose: 5000,
		className: 'bg-red text-white rounded-[12px]',
		icon: () => {
			return <Icon icon='warning' size={24} color='#fff' />
		}
	})
}
