import { useAtomValue } from 'jotai'
import { FC, useState } from 'react'

import { Card, MainLayout } from '@/components/ui'

import { profileAtom } from '@/store'

const Profile: FC = () => {
	const profile = useAtomValue(profileAtom)
	const [slideIndex, setSlideIndex] = useState(0)

	return (
		<MainLayout>
			<Card
				card={profile}
				slideIndex={slideIndex}
				setSlideIndex={setSlideIndex}
			/>
		</MainLayout>
	)
}

export default Profile
