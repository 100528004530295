// @ts-nocheck
import cn from 'clsx'
import { ChangeEvent } from 'react'
import { Controller } from 'react-hook-form'

import { Icon, Typography } from '@/components/ui'

import { IField } from './field.interface'

const Field = <T extends Record<string, any>>({
	control,
	rules,
	name,
	height,
	icon,
	className,
	disabled,
	onClick,
	typeField,
	maxLength,
	...props
}: IField<T>): JSX.Element => {
	const eventHoc =
		(
			callback: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
		) =>
		(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			if (maxLength && e.target.value.length > maxLength) return
			callback(e)
		}
	const FieldComponent = typeField === 'textarea' ? 'textarea' : 'input'

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { value, onChange }, fieldState: { error } }) => (
				<label
					className='w-full rounded-xl'
					onClick={onClick}
					style={{
						height: typeField === 'textarea' ? height : 'auto'
					}}
				>
					<div className='relative'>
						{icon && (
							<Icon
								icon={icon}
								size={24}
								className='absolute top-[50%] -translate-y-[50%] ml-4'
							/>
						)}
						<FieldComponent
							value={(value || '').toString()}
							onChange={eventHoc(onChange)}
							enterKeyHint='enter'
							disabled={disabled}
							autoComplete='off'
							className={cn(
								'input text-[17px] font-normal px-4 leading-[22px] w-full border rounded-xl bg-white outline-none',
								error && !error.type ? 'border-error' : 'border-white',
								icon && 'pl-12',
								typeField === 'textarea' && `overflow-auto resize-none`,
								typeField === 'textarea' ? 'py-4' : 'py-[13px]',
								disabled && 'bg-white opacity-100 text-gray',
								!value && 'text-dark-gray',
								className
							)}
							style={{
								height: typeField === 'textarea' ? height : 'auto'
							}}
							{...props}
						/>
					</div>
					{error?.message && (
						<Typography
							variant='text-small'
							className={cn(
								'mt-1 ml-4 leading-[18px]',
								!error.type ? 'text-error' : 'text-dark-gray'
							)}
						>
							{error.message}
						</Typography>
					)}
				</label>
			)}
		/>
	)
}

export default Field
