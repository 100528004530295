import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Field, Typography } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { countCharacters } from '@/utils/countCharacters'
import { EMOJI_REGEX } from '@/utils/regex'

import AuthLayout from '../AuthLayout'

const Emoji: FC = () => {
	const { tg } = useTelegram()
	const navigate = useNavigate()
	const profile = useAtomValue(profileAtom)
	const { event, getTimeSpent } = useAmplitude()
	const {
		uploadProfile,
		isUploadProfileSuccess,
		isUploadProfileError,
		isUploadProfileLoading
	} = useProfile()
	const { control, watch, setValue, setError, clearErrors } = useForm<{
		emoji: string
	}>({
		mode: 'onChange',
		defaultValues: {
			emoji: profile?.emoji || ''
		}
	})
	const { emoji } = watch()

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_active: false,
			text: 'Загрузка'
		})
		uploadProfile({ emoji })
	}

	useEffect(() => {
		if (isUploadProfileSuccess) {
			navigate('/auth/beautiful/photo')
			tg.HapticFeedback.notificationOccurred('success')
			const time = getTimeSpent()
			event('emoji_entered', {
				duration: time,
				number_of_emoji: countCharacters(emoji),
				emoji: emoji,
				flow: 'updating'
			})
		}
		if (isUploadProfileError) {
			tg.MainButton.hideProgress()
			setError('emoji', {})
			tg.MainButton.setParams({
				color: '#EF5652',
				is_visible: true,
				is_active: true,
				text: 'Продолжить'
			})
		}
	}, [isUploadProfileLoading])

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.BackButton.onClick(() => navigate('/auth/contacts'))
	}, [])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)

		const disabled = Boolean(!emoji || countCharacters(emoji) < 10)

		tg.MainButton.setParams({
			color: disabled ? '#F1BBBD' : '#EF5652',
			is_visible: true,
			is_active: !disabled,
			text: 'Продолжить'
		})

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [emoji])

	const handleEmojiChange = (value: string) => {
		if (countCharacters(value) > 20) {
			setError('emoji', {})
			tg.HapticFeedback.notificationOccurred('warning')
			setTimeout(() => {
				clearErrors('emoji')
			}, 250)
			return
		}
		if (EMOJI_REGEX.test(value)) {
			setValue('emoji', value)
		} else {
			if (countCharacters(value)) {
				setError('emoji', {})
				tg.HapticFeedback.notificationOccurred('warning')
				setTimeout(() => {
					clearErrors('emoji')
				}, 250)
			}
			const filteredValue = value.slice(0, -1)
			setValue('emoji', filteredValue)
		}
	}

	const getPlaceholder = () => {
		const keyboard =
			tg.platform === 'macos'
				? 'Открыть эмодзи: ⌃ + ⌘ + Пробел'
				: tg.platform === 'windows'
				? 'Открыть эмодзи: Win + ; или Win + .'
				: ''
		return (
			'Опиши свой путь 10-20 эмодзи:\nфлаги, семья, работа и что нравится\n' +
			keyboard
		)
	}

	return (
		<AuthLayout
			title='Опиши себя через эмодзи'
			description='Чем точнее заполнен раздел, тем лучше мы подберем подруг.'
		>
			<Field
				onKeyDown={e => {
					if (e.key === 'Enter') {
						const target = e.target as HTMLInputElement
						target.blur()
					}
				}}
				className={cn(
					emoji
						? tg.platform === 'ios'
							? 'text-[26px] tracking-[3px] leading-[28px]'
							: tg.platform === 'android'
							? 'text-[20px] tracking-[3px] leading-[28px]'
							: 'text-[26px] tracking-[3px] leading-[28px]'
						: 'py-5'
				)}
				control={control}
				onChange={e =>
					handleEmojiChange(e.target.value.replace(/[\r\n]+/g, ''))
				}
				height={['macos', 'windows'].includes(tg.platform) ? 110 : 90}
				name='emoji'
				type='text'
				typeField='textarea'
				placeholder={getPlaceholder()}
			/>
			<Typography
				variant='text-small'
				className='text-dark-gray leading-[18px] -mt-1 ml-auto pr-4'
			>
				{countCharacters(emoji)}/20
			</Typography>

			<div className='flex flex-col gap-2 mt-2 text-dark-gray'>
				<Typography variant='text-mini' className='leading-[18px]'>
					Например:
				</Typography>
				<Typography variant='text' className='leading-[18px]'>
					Малика Николаева
				</Typography>
				<span
					className={cn(
						'tracking-[3px] leading-[28px]',
						tg.platform === 'ios' ? 'text-[28px]' : 'text-[22px]'
					)}
				>
					🇰🇿🇷🇺👨‍👩‍👧🐶📚💃🏻☀️🍒🥂🧘🏽‍♀️🏊🏻‍♀️💻🇦🇺💡❤️‍🔥💰🧿💎⚡️🚀
				</span>
			</div>
		</AuthLayout>
	)
}

export default Emoji
