import cn from 'clsx'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Field, Typography } from '@/components/ui'

import { useTelegram } from '@/hooks'
import { countCharacters } from '@/utils/countCharacters'
import { DESCRIPTION_REGEX } from '@/utils/regex'

import { IDescription } from './media.interface'

const texts = {
	beautiful: 'Расскажи о своих интересах, достижениях или хобби',
	professional: 'Расскажи, чем ты занимаешься и как давно.',
	authentic: 'Честно и с юмором: твои слабости, странности или смешные моменты.'
}

const tabs = {
	beautiful: [
		{ value: false, text: 'Экстраверт' },
		{ value: true, text: 'Интроверт' }
	],
	professional: [
		{ value: true, text: 'Готова к работе' },
		{ value: false, text: 'Не обсуждаю работу' }
	]
}

const placeholder = {
	beautiful: 'Расскажи о себе',
	professional: 'Расскажи о своей деятельности',
	authentic: 'Расскажи о себе с самоиронией'
}

const Description: FC<IDescription> = ({
	type,
	isError,
	setText,
	setTag,
	tag,
	text
}) => {
	const { tg } = useTelegram()
	const { control, watch, setValue, setError, clearErrors } = useForm<{
		description: string
	}>({
		defaultValues: {
			description: text || ''
		},
		mode: 'onChange'
	})
	const { description } = watch()

	useEffect(() => {
		if (isError) setError('description', {})
	}, [isError])

	useEffect(() => {
		setText(description)
	}, [description])

	return (
		<>
			{tabs[type] && (
				<div className='border-2 border-gray bg-gray rounded-xl flex'>
					{tabs[type].map((tab, index) => (
						<button
							key={index}
							onClick={() => {
								tg.HapticFeedback.selectionChanged()
								setTag(tab.value)
							}}
							className={cn(
								'flex-[50%] rounded-xl px-2.5 py-[5px]',
								tab.value === tag && 'bg-white'
							)}
						>
							<Typography
								variant={tab.value === tag ? 'text-small-bold' : 'text-small'}
								className='transition-all duration-100 text-dark'
							>
								{tab.text}
							</Typography>
						</button>
					))}
				</div>
			)}
			<div className='flex flex-col gap-3'>
				<Typography
					variant='text-mini'
					className='w-full text-black leading-[18px] tracking-[0.3px]'
				>
					{texts[type]}
				</Typography>
				<Field
					control={control}
					onChange={e => {
						if (DESCRIPTION_REGEX.test(e.target.value)) {
							clearErrors('description')
							setValue('description', e.target.value)
						} else {
							tg.HapticFeedback.notificationOccurred('warning')
							setError('description', {})
							setTimeout(() => {
								clearErrors('description')
							}, 250)
						}
					}}
					height={160}
					name='description'
					type='text'
					typeField='textarea'
					placeholder={placeholder[type]}
				/>
			</div>
			<Typography
				variant='text-small'
				className='text-dark-gray ml-auto pr-4 leading-[18px] -mt-1'
			>
				{countCharacters(description)}/180
			</Typography>
		</>
	)
}

export default Description
