import cn from 'clsx'
import { FC, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

import { Button } from '@/components/ui'

import { useTelegram } from '@/hooks'

import { IBottomSheet } from './bottom-sheet.interface'

const BottomSheet: FC<PropsWithChildren<IBottomSheet>> = ({
	button,
	onSubmit,
	children,
	isOpen,
	onClose
}) => {
	const { tg } = useTelegram()
	const handleClick = () => {
		onClose()
		onSubmit?.()
	}

	return ReactDOM.createPortal(
		<div
			className={cn(
				'absolute flex bottom-0 top-0 z-50 left-0 right-0 w-full',
				!isOpen && 'hidden'
			)}
		>
			<div
				onClick={onClose}
				style={{
					background: 'rgba(0, 0, 0, 0.5)'
				}}
				className='fixed left-0 right-0 bottom-0 top-0'
			/>
			<div
				className={cn(
					'bottom-sheet bg-white mt-auto relative z-10 flex flex-col gap-6 w-full px-4 rounded-t-xl bottom-sheet', // Добавьте класс bottom-sheet
					['ios', 'android'].includes(tg.platform) ? 'py-8' : 'pt-8 pb-2',
					{ open: isOpen }
				)}
			>
				{children}
				{button && (
					<Button variant='primary' onClick={handleClick}>
						{button}
					</Button>
				)}
			</div>
		</div>,
		document.body
	)
}

export default BottomSheet
