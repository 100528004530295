import cn from 'clsx'
import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Icon, Typography } from '@/components/ui'

import { useAmplitude } from '@/hooks'

import type { IMenuItem } from './menu.interface'

const MenuItem: FC<IMenuItem> = ({ path, iconName, name }) => {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { event } = useAmplitude()
	const isActive =
		pathname === '/friend' && path === '/friends' ? true : pathname === path

	const handleClick = () => {
		navigate(path)
		event('tab_bar_selection', {
			tab: path.slice(1)
		})
	}
	return (
		<button
			className='items-center h-full w-[33.333%] flex flex-col gap-1 justify-center'
			onClick={handleClick}
		>
			<Icon
				size={33}
				className='w-[40px] h-[33px]'
				icon={iconName}
				color={isActive ? '#EF5652' : '#9A9DA0'}
			/>

			<Typography
				variant='menu'
				className={cn('leading-3', isActive ? 'text-accent' : 'text-dark-gray')}
			>
				{name}
			</Typography>
		</button>
	)
}

export default MenuItem
