import { useMutation } from '@tanstack/react-query'

import { SearchService } from '@/services/search.service'

export const useCard = ({
	search_id,
	to_id
}: {
	search_id: string
	to_id: number
}) => {
	const { mutate: feedback, data } = useMutation(
		['feedback'],
		(button: string) => SearchService.feedback({ search_id, to_id, button })
	)

	return {
		feedback,
		data
	}
}
