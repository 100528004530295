export const addParamsToCloudinaryUrl = (url, params) => {
	// Находим индекс, где начинается 'upload'
	const uploadIndex = url.indexOf('/upload')

	if (uploadIndex !== -1) {
		// Разделяем URL на две части: до и после 'upload/'
		const beforeUpload = url.slice(0, uploadIndex + 8) // +7 для включения '/upload/'
		const afterUpload = url.slice(uploadIndex + 7) // Остальная часть URL

		// Формируем новый URL с добавленными параметрами
		return `${beforeUpload}${params}${afterUpload}`
	}

	// Если 'upload' не найден, возвращаем оригинальный URL
	return url
}
