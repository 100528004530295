import cn from 'clsx'
import { motion } from 'framer-motion'
import { useAtomValue } from 'jotai/index'
import { FC, memo, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import 'swiper/css'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import { BottomSheet, Icon, Typography } from '@/components/ui'

import { useAmplitude, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { addParamsToCloudinaryUrl } from '@/utils/addParamsToCloudinaryUrl'
import { countCharacters } from '@/utils/countCharacters'
import { encryptionField } from '@/utils/encryptionField'
import { notifyMessage, notifyWarning } from '@/utils/toastNotifications'

import { ICardProps } from './card.interface'
import { useCard } from './useCard'

const Card: FC<ICardProps> = ({
	card,
	isActive,
	searchId,
	slideIndex,
	setSlideIndex,
	addMatchCard,
	isMatch,
	removeSlide,
	addLikedCard,
	removeLikedCard,
	isLikeCard,
	setIsFirstCardLoading,
	className
}) => {
	const { pathname } = useLocation()
	const { event } = useAmplitude()
	const { tg } = useTelegram()
	const profile = useAtomValue(profileAtom)
	const navigate = useNavigate()
	const user_id =
		typeof card.user_id === 'number'
			? card.user_id
			: Number(encryptionField(card.user_id))
	const { feedback, data } = useCard({
		search_id: searchId,
		to_id: user_id
	})
	const [showStatusModal, setShowStatusModal] = useState(false)
	const [showStarModal, setShowStarModal] = useState(false)
	const [showSelect, setShowSelect] = useState(false)
	const [isPictureLoading, setIsPictureLoading] = useState(false)
	const [visibleFullDescription, setVisibleFullDescription] = useState(false)
	const [isAnimation, setIsAnimation] = useState(false)
	const [isLike, setIsLike] = useState(isLikeCard)

	const contentRef = useRef(null)
	const durationRef = useRef(0)
	const [swiper, setSwiper] = useState<SwiperClass>(null)

	const [statusModal, setStatusModal] = useState<'like' | 'match'>('like')
	const [status, setStatus] = useState(isMatch ? 'like' : 'unlike')

	const [descriptionWidth, setDescriptionWidth] = useState(0)
	const [contentHeight, setContentHeight] = useState(window.innerHeight)

	useEffect(() => {
		setVisibleFullDescription(false)
	}, [isActive])

	useEffect(() => {
		if (swiper && swiper.realIndex !== slideIndex) {
			swiper.slideTo(slideIndex, 0)
		}
	}, [slideIndex, isActive])

	useEffect(() => {
		if (isActive && isPictureLoading) {
			setTimeout(() => {
				setIsFirstCardLoading?.(isPictureLoading)
			}, 300)
			// TODO: убрать, когда не будет update flow
			event('profile_viewed', {
				screen: pathname.slice(1),
				search_id: searchId,
				profile_id: user_id,
				...(card.description.beautiful.introvert !== null && {
					introvert: card.description.beautiful.introvert
				}),
				...(card.description.professional.ready_to_work !== null && {
					ready_to_work: card.description.professional.ready_to_work
				})
			})
		}
	}, [isActive, isPictureLoading])

	const getMediaType = (public_id: string) => {
		const types = ['beautiful', 'professional', 'authentic']
		return types.find(type => card.media[type].public_id === public_id)
	}

	useEffect(() => {
		if (!isActive) return

		const photoId =
			slideIndex === 0
				? card.media.beautiful.public_id
				: slideIndex === 1
				? card.media.professional.public_id
				: card.media.authentic.public_id
		event('photo_viewed', {
			photo_num: slideIndex + 1,
			search_id: searchId,
			photo_id: photoId,
			profile_id: user_id,
			screen: pathname.slice(1),
			...(card.description.beautiful.introvert !== null && {
				introvert: card.description.beautiful.introvert
			}),
			...(card.description.professional.ready_to_work !== null && {
				ready_to_work: card.description.professional.ready_to_work
			}),
			personality_side: getMediaType(photoId)
		})
	}, [slideIndex, isActive])

	useEffect(() => {
		if (data && data.is_match) {
			feedback('like')
			addMatchCard(card.user_id)
			setStatus('like')
			setStatusModal('match')
			setShowStatusModal(true)
			event('matched', {
				search_id: searchId,
				profile_id: user_id
			})
		}
	}, [data])

	useEffect(() => {
		if (descriptionWidth) return
		setDescriptionWidth(contentRef.current?.offsetWidth - 48 - 16)
		window.addEventListener('resize', () => {
			setDescriptionWidth(contentRef.current?.offsetWidth - 48 - 16)
		})
	}, [])

	useEffect(() => {
		if (visibleFullDescription) {
			const descriptionBlock = contentRef.current.querySelector('.description')
			if (
				descriptionBlock.scrollHeight === 44 ||
				descriptionBlock.scrollHeight === 22
			) {
				setVisibleFullDescription(false)
			} else {
				durationRef.current = Date.now()
			}
		} else if (![22, 44, 1000].includes(contentHeight)) {
			const photoId =
				slideIndex === 0
					? card.media.beautiful.public_id
					: slideIndex === 1
					? card.media.professional.public_id
					: card.media.authentic.public_id
			const number_of_characters =
				countCharacters(card.description.beautiful.text) +
				countCharacters(card.description.professional.text) +
				countCharacters(card.description.authentic.text)
			const duration = Math.floor((Date.now() - durationRef.current) / 1000)

			event('description_viewed', {
				profile_id: user_id,
				search_id: searchId,
				number_of_characters: number_of_characters,
				screen: pathname.slice(1),
				duration: duration,
				...(card.description.beautiful.introvert !== null && {
					introvert: card.description.beautiful.introvert
				}),
				...(card.description.professional.ready_to_work !== null && {
					ready_to_work: card.description.professional.ready_to_work
				}),
				personality_side: getMediaType(photoId)
			})
		}
	}, [visibleFullDescription])

	const handleClickTelegram = () => {
		event('go_to_telegram', {
			screen: pathname.slice(1),
			search_id: searchId,
			profile_id: user_id
		})
		if (pathname === '/friends') feedback('tg')

		if (pathname === '/friend') {
			tg.openTelegramLink(`https://t.me/${card.telegram}`)
		} else {
			tg.openTelegramLink(`https://t.me/${encryptionField(card.telegram)}`)
		}
	}

	const handleClickInstagram = () => {
		event('go_to_instagram', {
			screen: pathname.slice(1),
			search_id: searchId,
			profile_id: user_id
		})
		if (pathname === '/profile' || pathname === '/friend') {
			tg.openLink(`https://www.instagram.com/${card.instagram}/`)
		} else {
			feedback('inst')
			tg.openLink(
				`https://www.instagram.com/${encryptionField(card.instagram)}/`
			)
		}
	}

	const handleClickLike = () => {
		tg.HapticFeedback.notificationOccurred('success')
		const photoId =
			slideIndex === 0
				? card.media.beautiful.public_id
				: slideIndex === 1
				? card.media.professional.public_id
				: card.media.authentic.public_id
		if (isLike) {
			feedback('unlike')
			event('unlike_clicked', {
				screen: 'community',
				search_id: searchId,
				profile_id: user_id,
				...(card.description.beautiful.introvert !== null && {
					introvert: card.description.beautiful.introvert
				}),
				...(card.description.professional.ready_to_work !== null && {
					ready_to_work: card.description.professional.ready_to_work
				}),
				personality_side: getMediaType(photoId)
			})
			removeLikedCard(card.user_id)
			setIsLike(false)
		} else if (card.status === 'like' && status !== 'like') {
			feedback('like')
			event('like_clicked', {
				screen: 'community',
				search_id: searchId,
				profile_id: user_id,
				...(card.description.beautiful.introvert !== null && {
					introvert: card.description.beautiful.introvert
				}),
				...(card.description.professional.ready_to_work !== null && {
					ready_to_work: card.description.professional.ready_to_work
				}),
				personality_side: getMediaType(photoId)
			})
			addMatchCard(card.user_id)
			setStatus('like')
			setStatusModal('match')
			setShowStatusModal(true)
			event('matched', {
				search_id: searchId,
				profile_id: user_id,
				...(card.description.beautiful.introvert !== null && {
					introvert: card.description.beautiful.introvert
				}),
				...(card.description.professional.ready_to_work !== null && {
					ready_to_work: card.description.professional.ready_to_work
				})
			})
		} else {
			feedback('like')
			event('like_clicked', {
				screen: 'community',
				search_id: searchId,
				profile_id: user_id,
				...(card.description.beautiful.introvert !== null && {
					introvert: card.description.beautiful.introvert
				}),
				...(card.description.professional.ready_to_work !== null && {
					ready_to_work: card.description.professional.ready_to_work
				}),
				personality_side: getMediaType(photoId)
			})
			setIsLike(true)
			addLikedCard(card.user_id)
			tg.CloudStorage.getItem('isFirstLike', (error, value) => {
				if (!value) {
					setStatusModal('like')
					setShowStatusModal(true)
					tg.CloudStorage.setItem('isFirstLike', 'true')
				}
			})
		}
	}

	const handleClickReject = () => {
		setShowSelect(false)
		removeSlide(card.user_id)
		notifyMessage('Профиль скрыт', 'eye-fill')
		const photoId =
			slideIndex === 0
				? card.media.beautiful.public_id
				: slideIndex === 1
				? card.media.professional.public_id
				: card.media.authentic.public_id
		event('reject_clicked', {
			screen: 'community',
			search_id: searchId,
			profile_id: user_id,
			...(card.description.beautiful.introvert !== null && {
				introvert: card.description.beautiful.introvert
			}),
			...(card.description.professional.ready_to_work !== null && {
				ready_to_work: card.description.professional.ready_to_work
			}),
			personality_side: getMediaType(photoId)
		})
		tg.HapticFeedback.notificationOccurred('warning')
		feedback('reject')
	}

	const handleClickReport = () => {
		setShowSelect(false)
		removeSlide(card.user_id)
		notifyWarning('Жалоба отправлена')
		const photoId =
			slideIndex === 0
				? card.media.beautiful.public_id
				: slideIndex === 1
				? card.media.professional.public_id
				: card.media.authentic.public_id
		event('report_clicked', {
			screen: 'community',
			search_id: searchId,
			profile_id: user_id,
			...(card.description.beautiful.introvert !== null && {
				introvert: card.description.beautiful.introvert
			}),
			...(card.description.professional.ready_to_work !== null && {
				ready_to_work: card.description.professional.ready_to_work
			}),
			personality_side: getMediaType(photoId)
		})
		tg.HapticFeedback.notificationOccurred('warning')
		feedback('report')
	}

	const handleClickSettings = () => {
		navigate('/settings')
		event('open_button_settings', {
			search_id: searchId,
			screen: 'profile'
		})
	}

	const imageHeight = window.innerHeight - (tg.platform === 'ios' ? 73 : 57)
	const roundToNearestHundred = (num: number) => Math.round(num / 100) * 100
	const boundedValue = (num: number) => Math.min(Math.max(num, 300), 1000)
	const paramValue = boundedValue(roundToNearestHundred(window.innerWidth * 2))
	const params = `ar_9:16,c_fill,g_center,w_${paramValue}/q_auto:eco/f_auto`

	const renderMediaElement = media => {
		if (!media) return null

		const mediaUrl = addParamsToCloudinaryUrl(media.url, params)

		if (media.resource_type === 'image') {
			return (
				<img
					className='object-cover w-full h-full'
					src={mediaUrl}
					alt=''
					draggable={false}
				/>
			)
		}
	}

	const descriptionHtml = ['beautiful', 'professional', 'authentic']
		.map(key =>
			card.description[key]?.text
				? `${card.description[key]?.text?.trim()}\n\n`
				: ''
		)
		.join('')
		.replaceAll('\n', '<br />')
	const emojiHtml = `<div style="font-size: ${
		tg.platform === 'ios' ? 28 : 22
	}px; letter-spacing: 3px; line-height: 28px;">${card.emoji}</div>`
	const fullDescriptionHtml = `${descriptionHtml}${emojiHtml}`

	return (
		<>
			<div
				id={card.user_id}
				onClick={() => {
					if (showSelect) setShowSelect(false)
				}}
				className={cn(
					'w-full h-full flex flex-col bg-dark p-4 cursor-pointer text-white overflow-hidden justify-between relative',
					className
				)}
			>
				<div className='flex gap-2 absolute top-2 right-2 left-2 z-30 pointer-events-none backdrop-blur-0'>
					<div
						className={cn(
							'w-full bg-white h-[3px] rounded-sm',
							slideIndex && 'opacity-25'
						)}
					/>
					<div
						className={cn(
							'w-full bg-white h-[3px] rounded-sm',
							slideIndex !== 1 && 'opacity-25'
						)}
					/>
					<div
						className={cn(
							'w-full bg-white h-[3px] rounded-sm',
							slideIndex !== 2 && 'opacity-25'
						)}
					/>
				</div>
				<Swiper
					slidesPerView={1}
					speed={240}
					touchStartPreventDefault={false}
					className='absolute top-0 bottom-0 left-0 right-0 backdrop-blur-0 z-20 bg-dark'
					spaceBetween={4}
					onSwiper={setSwiper}
					onSlideChange={swiper => {
						tg.HapticFeedback.selectionChanged()
						setSlideIndex?.(swiper.realIndex)
					}}
				>
					<SwiperSlide>
						<img
							className='object-cover w-full h-full'
							src={addParamsToCloudinaryUrl(card.media.beautiful.url, params)}
							alt=''
							draggable={false}
							onError={() => {
								setIsPictureLoading(true)
							}}
							onLoad={() => {
								setIsPictureLoading(true)
							}}
						/>
					</SwiperSlide>
					<SwiperSlide>
						{renderMediaElement(card.media.professional)}
					</SwiperSlide>
					<SwiperSlide>{renderMediaElement(card.media.authentic)}</SwiperSlide>
				</Swiper>

				<motion.div
					onClick={() => {
						visibleFullDescription
							? setVisibleFullDescription(!visibleFullDescription)
							: undefined
					}}
					animate={{
						background: visibleFullDescription
							? 'rgba(0, 0, 0, 0.5)'
							: 'rgba(0, 0, 0, 0.0)'
					}}
					initial={false}
					style={{
						pointerEvents: visibleFullDescription ? 'auto' : 'none'
					}}
					transition={{ duration: 0.02, ease: 'linear', delay: 0.001 }}
					className='h-[100%] mt-auto w-full absolute cursor-pointer left-0 right-0 bottom-0 z-30 backdrop-blur-0'
				/>
				<motion.div
					animate={{
						background: !visibleFullDescription
							? 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75))'
							: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.0))'
					}}
					initial={false}
					transition={{ duration: 0.02, ease: 'linear', delay: 0.001 }}
					className='h-[38%] mt-auto w-full cursor-pointer absolute pointer-events-none left-0 right-0 bottom-0 z-20 backdrop-blur-0'
				/>

				<div
					ref={contentRef}
					className='flex gap-3 mt-auto flex-row items-end cursor-pointer overflow-hidden justify-between w-full relative z-30'
				>
					<div className='flex flex-col gap-2 backdrop-blur-0'>
						<motion.div
							onAnimationComplete={() => {
								if (visibleFullDescription) {
									const descriptionBlock =
										contentRef.current.querySelector('.fake-description')
									setContentHeight(descriptionBlock.scrollHeight)
								}
							}}
							onClick={() => setVisibleFullDescription(false)}
							animate={{
								maxHeight: visibleFullDescription ? contentHeight : 44
							}}
							initial={false}
						>
							<Typography
								variant='text'
								className={cn(
									'pointer-events-none opacity-0 fake-description leading-[22px] tracking-[0.2px] break-words',
									!isAnimation && 'overflow-ellipsis'
								)}
								style={{ width: descriptionWidth ? descriptionWidth : 'auto' }}
								dangerouslySetInnerHTML={{
									__html: fullDescriptionHtml
								}}
							/>
						</motion.div>
						<div className='flex flex-col gap-2 pointer-events-none'>
							<div className='flex gap-1'>
								{card.description.beautiful.introvert !== null && (
									<div className='py-1 px-2 flex items-center gap-1 rounded-full bg-gray'>
										<Icon
											size={16}
											icon={
												card.description.beautiful.introvert
													? 'introvert'
													: 'extrovert'
											}
											color='#3D3E41'
										/>
										<Typography
											variant='text-small'
											className='text-dark leading-[18px]'
										>
											{card.description.beautiful.introvert
												? 'Интроверт'
												: 'Экстраверт'}
										</Typography>
									</div>
								)}
								{card.description.professional.ready_to_work && (
									<div className='py-1 px-2 flex items-center gap-1 rounded-full bg-gray'>
										<Icon size={16} icon='work' color='#3D3E41' />
										<Typography
											variant='text-small'
											className='text-dark leading-[18px]'
										>
											Обращайся
										</Typography>
									</div>
								)}
							</div>
							<div>
								<div className='flex items-center gap-1.5'>
									<Typography variant='h3' className='leading-[28px]'>
										{card.name.split(' ')[0]}
										{card.age && ', '}
										{card.age}
									</Typography>

									{card.star && (
										<button
											className='pointer-events-auto'
											onClick={() => setShowStarModal(!showStarModal)}
										>
											<Icon size={24} icon='star' color='#fff' />
										</button>
									)}
								</div>

								<Typography
									variant='text-mini'
									className='leading-[18px] tracking-[0.3px]'
								>
									{card.country.flag} {card.city['ru']}
									{card?.country ? `, ${card.country['ru']}` : ''}
								</Typography>
							</div>
						</div>
						<motion.div
							onAnimationStart={() => setIsAnimation(true)}
							onAnimationComplete={() =>
								!visibleFullDescription && setIsAnimation(false)
							}
							animate={{
								maxHeight: visibleFullDescription ? contentHeight : 44
							}}
							initial={false}
							transition={{ duration: 0.001 * contentHeight, ease: 'linear' }}
						>
							<Typography
								variant='text'
								className={cn(
									'cursor-pointer description leading-[22px] tracking-[0.2px] break-words',
									!isAnimation && 'overflow-ellipsis',
									visibleFullDescription && `max-h-[${imageHeight}px]`
								)}
								style={{ width: descriptionWidth ? descriptionWidth : 'auto' }}
								onClick={() => {
									if (contentHeight !== 44 && contentHeight !== 22) {
										setVisibleFullDescription(!visibleFullDescription)
									}
								}}
								dangerouslySetInnerHTML={{
									__html: fullDescriptionHtml
								}}
							/>
						</motion.div>
					</div>
					<div className='flex flex-col gap-2'>
						{isPictureLoading && (
							<>
								{pathname === '/profile' && (
									<button
										onClick={handleClickSettings}
										className='rounded-xl w-[51px] h-[51px] flex items-center justify-center backdrop-blur-md bg-white/10'
									>
										<Icon size={28} icon='settings' color='#fff' />
									</button>
								)}
								{pathname === '/community' && status === 'unlike' && (
									<>
										<button
											onClick={handleClickLike}
											className='rounded-xl w-[51px] h-[51px] flex items-center justify-center backdrop-blur-md bg-white/10'
										>
											<Icon
												size={28}
												icon={isLike ? 'like' : 'unlike'}
												color={isLike ? '#EF5652' : '#fff'}
											/>
										</button>
										<button
											onClick={() => setShowSelect(true)}
											className='rounded-xl w-[51px] h-[51px] flex items-center justify-center backdrop-blur-md bg-white/10'
										>
											<Icon size={28} icon='dots' color='#fff' />
										</button>
									</>
								)}
								{((pathname === '/community' && status === 'like') ||
									pathname === '/friend') && (
									<>
										<button
											onClick={handleClickTelegram}
											className='rounded-xl w-[51px] h-[51px] flex items-center justify-center backdrop-blur-md bg-white/10'
										>
											<Icon size={28} icon='telegram' color='#fff' />
										</button>
										{card.instagram && (
											<button
												onClick={handleClickInstagram}
												className='rounded-xl w-[51px] h-[51px] flex items-center justify-center backdrop-blur-md bg-white/10'
											>
												<Icon size={28} icon='instagram' color='#fff' />
											</button>
										)}
									</>
								)}
							</>
						)}
					</div>
				</div>
			</div>
			{showSelect && (
				<div className='absolute z-30 right-4 bottom-4 bg-white overflow-hidden rounded-xl'>
					<button
						className='flex items-center gap-4 py-3 px-4'
						onClick={handleClickReject}
					>
						<Icon size={24} icon='eye-fill' color='#202022' />
						<Typography variant='text-mini'>Скрыть профиль</Typography>
					</button>
					<div className='w-full bg-gray opacity-25 h-[1px] ml-4' />
					<button
						className='flex items-center gap-4 py-3 px-4'
						onClick={handleClickReport}
					>
						<Icon size={24} icon='warning' color='#EF3B35' />
						<Typography variant='text-mini' className='text-red'>
							Пожаловаться
						</Typography>
					</button>
				</div>
			)}
			<BottomSheet
				isOpen={showStarModal}
				onClose={() => setShowStarModal(!showStarModal)}
				button='Понятно'
			>
				<div className='flex flex-col gap-4 items-center'>
					<Icon size={64} icon='big-star' color='#EF5652' />
					<div className='flex flex-col gap-1 text-center text-dark items-center'>
						<Typography variant='h3' className='leading-7'>
							Звезда Frienda
						</Typography>
						<Typography
							variant='text'
							className='leading-[22px] tracking-[0.2px]'
						>
							{card.name.split(' ')[0]} — участница Frienda, кто активно
							формирует дух и ценности нашего приложения.
						</Typography>
					</div>
				</div>
			</BottomSheet>
			<BottomSheet
				isOpen={showStatusModal}
				onClose={() => setShowStatusModal(!showStatusModal)}
				button='Понятно'
			>
				<div className='flex flex-col gap-4 items-center'>
					<div className='relative flex w-fit'>
						{statusModal === 'like' && (
							<>
								<img
									src={addParamsToCloudinaryUrl(
										card.media.beautiful.url,
										'ar_1.0,c_thumb,g_face,w_600,z_0.1'
									)}
									alt=''
									className='w-20 h-20 rounded-full object-cover'
								/>
							</>
						)}
						{statusModal === 'match' && (
							<>
								<img
									src={addParamsToCloudinaryUrl(
										profile.media.beautiful.url,
										'ar_1.0,c_thumb,g_face,w_600,z_0.1'
									)}
									alt=''
									className='w-20 h-20 rounded-full object-cover'
								/>
								<img
									src={addParamsToCloudinaryUrl(
										card.media.beautiful.url,
										'ar_1.0,c_thumb,g_face,w_600,z_0.1'
									)}
									alt=''
									className='w-20 h-20 rounded-full object-cover -ml-4 outline outline-white'
								/>
							</>
						)}

						<Icon
							size={22}
							icon='like'
							color='#FF3B30'
							className='absolute -bottom-0.5 right-0'
						/>
					</div>

					<div className='flex flex-col gap-1 text-center text-dark items-center'>
						<Typography variant='h3' className='leading-7'>
							{statusModal === 'like' && 'Запрос отправлен'}
							{statusModal === 'match' && 'Поздравляем, вы подруги!'}
						</Typography>
						{statusModal === 'like' && (
							<Typography
								variant='text'
								className='max-w-[333px] leading-[22px] tracking-[0.2px]'
							>
								{card.name.split(' ')[0]} узнает, что получила лайк, но не
								увидит от кого. Если она лайкнет тебя в ответ, вы сможете начать
								общение.
							</Typography>
						)}
						{statusModal === 'match' && (
							<Typography
								variant='text'
								className='max-w-[333px] leading-[22px] tracking-[0.2px]'
							>
								Контакты {card.name.split(' ')[0]} открыты, <br /> начните
								общаться.
							</Typography>
						)}
					</div>
				</div>
			</BottomSheet>
		</>
	)
}

export default memo(Card)
