import { Controller } from 'react-hook-form'

import FileField from './FileField'
import { IFileFieldControl } from './file-field.interface'

const FileFieldController = <T extends Record<string, any>>({
	control,
	rules,
	name,
	...props
}: IFileFieldControl<T>) => {
	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			render={({ field: { value, onChange, name, disabled } }) => (
				<FileField
					name={name}
					value={value}
					onChange={onChange}
					disabled={disabled}
					{...props}
				/>
			)}
		/>
	)
}

export default FileFieldController
