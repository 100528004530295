import { FC, PropsWithChildren, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ButtonLayout, Icon, Typography } from '@/components/ui'

import { useTelegram } from '@/hooks'

interface IProps {
	title?: string
	description?: string
	footer?: {
		icon: string
		title: string
	}
}

const AuthLayout: FC<PropsWithChildren<IProps>> = ({
	children,
	title,
	description,
	footer
}) => {
	const { tg } = useTelegram()
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const register = JSON.parse(localStorage.getItem('register'))

	useEffect(() => {
		if (register) {
			tg.SettingsButton.show()
			tg.SettingsButton.onClick(() => navigate('/settings'))
		} else {
			tg.SettingsButton.hide()
		}

		tg.MainButton.show()
		tg.BackButton.show()
	}, [])

	const getProgressBars = () => {
		switch (pathname) {
			case '/auth/name':
				return 1
			case '/auth/date':
				return 2
			case '/auth/address':
				return 3
			case '/auth/contacts':
				return 4
			case '/auth/emoji':
				return 5
			case '/auth/beautiful/photo':
				return 6
			case '/auth/beautiful/description':
				return 7
			case '/auth/professional/photo':
				return 8
			case '/auth/professional/description':
				return 9
			case '/auth/authentic/photo':
				return 10
			case '/auth/authentic/description':
				return 11
			case '/auth/face-liveness/start':
				return 12
			case '/auth/face-liveness/finish':
				return 13
			case '/auth/payment':
				return 14
		}
	}

	return (
		<ButtonLayout>
			<div className='flex flex-col gap-3 h-full overflow-y-hidden'>
				<div className='pb-1.5 mt-[3px]'>
					<div className='relative rounded-sm overflow-hidden w-full h-[3px] bg-gray'>
						<div
							className='absolute h-full bg-accent'
							style={{ width: `${(getProgressBars() / 14) * 100}%` }}
						/>
					</div>
				</div>
				{title && (
					<Typography variant='h3' className='w-full leading-7'>
						{title}
					</Typography>
				)}
				{description && (
					<Typography
						variant='text-mini'
						className='w-full text-black leading-[18px] tracking-[0.3px]'
					>
						{description}
					</Typography>
				)}
				{children}

				{footer && (
					<div className='mt-auto flex items-center gap-4 mb-2'>
						<Icon
							size={26}
							icon={footer.icon}
							color='#202022'
							className='flex-shrink-0'
						/>
						<Typography variant='text-mini' className='w-full leading-[18px]'>
							{footer.title}
						</Typography>
					</div>
				)}
			</div>
		</ButtonLayout>
	)
}

export default AuthLayout
