import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon, Typography } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'

import AuthLayout from '../AuthLayout'

const FaceLivenessStart: FC = () => {
	const { tg } = useTelegram()
	const navigate = useNavigate()
	const {
		livenessStartSession,
		isLivenessStartSessionLoading,
		isLivenessStartSessionSuccess
	} = useProfile()
	const { event, getTimeSpent } = useAmplitude()

	const items = [
		'Для завершения регистрации нужно подтвердить, что аккаунт твой.',
		'Разреши доступ к камере и следуй указаниям — процесс займет минуту.',
		'Система сверит лицо на видео с добавленными ранее фото.'
	]

	const onSubmit = () => {
		livenessStartSession()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_visible: true,
			is_active: false,
			text: 'Загрузка'
		})
	}

	useEffect(() => {
		if (isLivenessStartSessionSuccess) {
			const time = getTimeSpent()
			event('liveness_started', {
				duration: time
			})
			tg.MainButton.hideProgress()
			navigate('/auth/face-liveness/check')
		}
	}, [isLivenessStartSessionLoading])

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.MainButton.setParams({
			color: '#EF5652',
			is_active: true,
			text: 'Начать проверку'
		})
		tg.BackButton.onClick(() => navigate('/auth/authentic/description'))
		tg.MainButton.onClick(onSubmit)

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [])

	return (
		<AuthLayout>
			<div className='flex flex-col gap-4'>
				<div className='flex flex-col items-center gap-2'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='113'
						height='112'
						viewBox='0 0 113 112'
						fill='none'
					>
						<g clipPath='url(#clip0_5662_7349)'>
							<path
								d='M53.5 78C68.964 78 81.5 65.464 81.5 50C81.5 34.536 68.964 22 53.5 22C38.036 22 25.5 34.536 25.5 50C25.5 65.464 38.036 78 53.5 78Z'
								fill='#FFDFD4'
							/>
							<path
								d='M85 52.4427C85 69.7362 82.5 92 82.5 92H25C25 92 21 69.7362 21 52.4427C21 35.1493 35.4 21 52.9999 21C70.6 21 85 35.1493 85 52.4427ZM27.3999 52.4427C27.3999 66.309 38.8879 77.5972 52.9999 77.5972C67.1121 77.5972 78.6001 66.309 78.6001 52.4427C78.6001 49.9588 78.216 47.5693 77.5441 45.3368C71.3576 46.7506 64.8825 46.3179 58.9478 44.0941C53.0133 41.8702 47.8893 37.9564 44.2319 32.8539C41.0959 40.3686 35.112 46.4372 27.56 49.7386C27.3999 50.6192 27.3999 51.5309 27.3999 52.4427Z'
								fill='#202022'
							/>
							<path
								d='M25.0208 95.0999C25.007 94.9476 25 94.7927 25 94.6352V92.644C25 87.3333 43.768 79.0024 43.768 79.0024C51.1921 81.9204 55.439 81.9433 62.7566 79C62.7566 79 82 87.1736 82 92.644V94.6352C82 94.6981 81.9988 94.7607 81.9966 94.8226C73.7728 99.9895 63.9012 103 53.2857 103C42.8614 103 33.1546 100.097 25.0208 95.0999Z'
								fill='#EF3B35'
							/>
							<path
								d='M111 76.15C105.674 76.15 100.484 74.2168 95.3999 70.3074C95.1403 70.1079 94.8245 70 94.4999 70C94.1754 70 93.8596 70.1079 93.5999 70.3074C88.516 74.2168 83.326 76.15 77.9999 76.15C77.6022 76.15 77.2207 76.3118 76.9394 76.6002C76.658 76.8885 76.5 77.2797 76.5 77.6874V88.4498C76.5 98.7017 82.4159 106.235 93.9499 110.893C94.3034 111.036 94.6966 111.036 95.0499 110.893C106.584 106.235 112.5 98.6997 112.5 88.4498V77.6874C112.5 77.2797 112.342 76.8885 112.061 76.6002C111.779 76.3118 111.398 76.15 111 76.15Z'
								fill='#F2F2F7'
							/>
							<path
								d='M94.4975 99.9947C100.019 99.9947 104.495 95.2948 104.495 89.4973C104.495 83.6998 100.019 79 94.4975 79C88.976 79 84.5 83.6998 84.5 89.4973C84.5 95.2948 88.976 99.9947 94.4975 99.9947Z'
								fill='#202022'
							/>
							<path
								d='M107.71 79.6957C103.421 79.6957 99.2412 78.2344 95.1468 75.2794C94.9377 75.1284 94.6834 75.0469 94.4221 75.0469C94.1606 75.0469 93.9063 75.1284 93.6972 75.2794C89.6028 78.2344 85.4231 79.6957 81.1337 79.6957C80.8134 79.6957 80.5061 79.8181 80.2795 80.0361C80.0531 80.2541 79.9258 80.5497 79.9258 80.8579V88.9932C79.9258 96.7427 84.6902 102.437 93.9791 105.958C94.2638 106.066 94.5804 106.066 94.8649 105.958C104.154 102.437 108.918 96.7412 108.918 88.9932V80.8579C108.918 80.5497 108.791 80.2541 108.565 80.0361C108.338 79.8181 108.031 79.6957 107.71 79.6957ZM102.084 86.3635L92.42 94.8863C92.1904 95.0885 91.8885 95.1977 91.5774 95.191C91.2661 95.1844 90.9697 95.0624 90.7496 94.8507L86.7229 90.9767C86.6042 90.8703 86.509 90.7419 86.443 90.5994C86.377 90.4569 86.3415 90.3029 86.3386 90.1469C86.3357 89.9908 86.3655 89.8359 86.4263 89.6911C86.4871 89.5464 86.5775 89.415 86.6922 89.3046C86.8069 89.1942 86.9436 89.1073 87.094 89.0487C87.2444 88.9903 87.4055 88.9616 87.5678 88.9644C87.73 88.9672 87.8899 89.0013 88.0381 89.0648C88.1862 89.1283 88.3196 89.2199 88.4302 89.3341L91.6404 92.4209L100.451 84.6497C100.687 84.4413 101 84.3318 101.32 84.3452C101.64 84.3585 101.942 84.4937 102.158 84.721C102.375 84.9483 102.489 85.249 102.475 85.557C102.461 85.865 102.32 86.1552 102.084 86.3635Z'
								fill='#A3E165'
							/>
						</g>
						<defs>
							<clipPath id='clip0_5662_7349'>
								<rect
									width='112'
									height='112'
									fill='white'
									transform='translate(0.5)'
								/>
							</clipPath>
						</defs>
					</svg>
					<Typography
						variant='h3'
						className='max-w-[260px] text-center leading-7'
					>
						Мы заботимся <br /> о твоей безопасности
					</Typography>
				</div>

				<div className='flex flex-col gap-3 items-center ml-4'>
					{items.map((item, index) => (
						<div key={index} className='flex gap-2'>
							<Icon
								size={16}
								icon='check'
								color='#202022'
								className='flex-shrink-0'
							/>
							<Typography
								variant='text-mini'
								className='text-black w-full leading-[18px] tracking-[0.3px]'
							>
								{item}
							</Typography>
						</div>
					))}
				</div>
			</div>
		</AuthLayout>
	)
}

export default FaceLivenessStart
