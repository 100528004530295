import { FC, useCallback } from 'react'

import { Typography } from '@/components/ui'

interface IProps {
	type: string
}

const CardEnd: FC<IProps> = ({ type }) => {
	const getDescription = useCallback(() => {
		switch (type) {
			case 'friends': {
				return 'Ставь лайки на интересные профили, чтобы появились подруги'
			}
			case 'community': {
				return 'Возвращайся позже, когда добавится больше участниц'
			}
		}
	}, [type])

	return (
		<div className='h-full w-full flex flex-col relative bg-light-gray items-center justify-center gap-6 p-6'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='59'
				height='82'
				viewBox='0 0 59 82'
				fill='none'
			>
				<path
					d='M3.90968 28.3568C5.13853 41.3138 20.4624 45.4437 24.4221 58.9682C27.678 70.0757 19.7482 75.4561 23.4348 79.1551C28.8124 84.546 50.0285 78.0307 56.4983 63.8757C60.479 55.1641 57.5066 48.1654 56.5403 46.3895C52.7172 39.3277 51.6774 36.9528 39.599 24.4581C23.6239 7.94916 15.6311 -0.300051 12.7742 1.1186C10.0224 2.48472 10.1485 11.417 16.9019 18.9306C17.5321 19.6347 18.0678 20.2021 18.5929 20.7276C20.6095 22.7662 22.0379 24.0062 23.6029 25.4249C23.6029 25.4249 24.7267 26.4337 28.3082 29.9331C34.7571 36.2172 39.4099 41.8918 41.9201 45.1494C43.9892 47.8501 45.4281 50.9186 45.9113 54.0397C46.5415 58.1485 45.1761 60.0295 44.2098 60.1451C43.0965 60.2818 42.0252 60.2923 35.8809 51.5491C33.5072 48.1654 31.2806 45.7169 26.8378 40.8094C23.981 37.6568 22.0799 35.6497 20.5885 34.126C19.4226 32.928 17.5321 30.9734 13.7615 27.1273C9.32924 22.6086 7.0921 20.3493 6.11532 20.4964C4.19326 20.7696 3.6156 25.0361 3.93069 28.3568H3.90968Z'
					fill='#F0A4B7'
				/>
				<path
					d='M55.0905 28.3568C53.8617 41.3138 38.5378 45.4437 34.5781 58.9682C31.3222 70.0757 39.252 75.4561 35.5654 79.1551C30.1879 84.546 8.97176 78.0307 2.50189 63.8757C-1.47875 55.1641 1.4936 48.1654 2.45988 46.3895C6.28298 39.3277 7.32278 36.9528 19.4013 24.4581C35.3764 7.94916 43.3692 -0.300051 46.226 1.1186C48.9778 2.48472 48.8517 11.417 42.0983 18.9306C41.4681 19.6347 40.9325 20.2021 40.4073 20.7276C38.3907 22.7662 36.9623 24.0062 35.3974 25.4249C35.3974 25.4249 34.2735 26.4337 30.692 29.9331C24.2432 36.2172 19.5903 41.8918 17.0801 45.1494C15.011 47.8501 13.5721 50.9186 13.0889 54.0397C12.4588 58.1485 13.8242 60.0295 14.7904 60.1451C15.9038 60.2818 16.9751 60.2923 23.1193 51.5491C25.493 48.1654 27.7197 45.7169 32.1624 40.8094C35.0193 37.6568 36.9203 35.6497 38.4117 34.126C39.5776 32.928 41.4681 30.9734 45.2387 27.1273C49.671 22.6086 51.9081 20.3493 52.8849 20.4964C54.807 20.7696 55.3846 25.0361 55.0695 28.3568H55.0905Z'
					fill='#EF3B35'
				/>
			</svg>
			<div className='flex flex-col items-center text-center justify-center gap-1'>
				<Typography variant='h3' className='leading-[28px]'>
					Пока никого
				</Typography>
				<Typography
					variant='text'
					className='max-w-[327px] leading-[22px] text-black'
				>
					{getDescription()}
				</Typography>
			</div>
		</div>
	)
}

export default CardEnd
