import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { FileFieldController, Icon } from '@/components/ui'

import { profileAtom } from '@/store'
import { notifyError } from '@/utils/toastNotifications'

import { IPhoto } from './media.interface'

const errors = {
	media_upload_error:
		'Ошибка загрузки фото. Попробуй добавить его снова или выбери другое.',
	media_moderation_error:
		'Выбранное фото не прошло проверку. Пожалуйста, выбери другое, подходящее для профиля.',
	media_multiple_faces_error:
		'На фото должно быть только твоё лицо. Загрузи одиночное фото.',
	media_no_faces_error:
		'На фото должно быть видно твоё лицо. Пожалуйста, выбери подходящее фото.',
	image_face_compare_error:
		'На фото должен быть человек, который проходил проверку.'
}

const Photo: FC<IPhoto> = ({
	error,
	setPhoto,
	photo,
	type,
	isLoading,
	setHasPhoto
}) => {
	const profile = useAtomValue(profileAtom)
	const photoFromStorage = profile?.media ? profile?.media?.[type]?.url : null
	const { control, watch, setValue } = useForm<{
		media: FileList
		mode: 'onChange'
	}>()
	const { media } = watch()

	useEffect(() => {
		const photoElement = document.getElementById('photo') as HTMLImageElement
		if (media && media[0]) {
			setPhoto(media[0])
			const reader = new FileReader()

			reader.onloadend = function () {
				if (reader.result) {
					photoElement.src = reader.result as string
				} else {
					console.log('error load')
					setValue('media', null)
				}
			}

			reader.onerror = function () {
				console.log('error main')
				setValue('media', null)
			}

			reader.readAsDataURL(media[0])
		} else if (photoFromStorage) {
			photoElement.src = photoFromStorage
		}
	}, [media])

	useEffect(() => {
		// если фото не парсится в FileReader
		if (profile?.media && profile?.media?.[type]?.url && !media) {
			const photoElement = document.getElementById('photo') as HTMLImageElement
			photoElement.src = profile?.media?.[type]?.url
		}
	}, [profile])

	useEffect(() => {
		if (!photo && photoFromStorage) {
			setHasPhoto(true)
		}
	}, [])

	useEffect(() => {
		if (error) {
			notifyError(
				errors[error] ||
					'Что-то пошло не так при загрузке. Пожалуйста, попробуй ещё раз'
			)
			setPhoto(null)
		}
	}, [error])

	return (
		<div className='aspect-[343/456] bg-white relative rounded-xl overflow-hidden'>
			<img
				alt=''
				id='photo'
				className={cn(
					'absolute object-cover h-full w-full top-0 bottom-0 left-0 right-0',
					!(photoFromStorage || media) && 'hidden',
					(isLoading || error) && 'opacity-50'
				)}
			/>
			{!(photoFromStorage || media) && (
				<div
					className={cn(
						'absolute z-20 left-[50%] top-[50%] transform -translate-x-[50%] -translate-y-[50%] p-2 opacity-50 rounded-xl',
						error ? 'bg-red' : 'bg-dark'
					)}
				>
					<Icon size={24} icon='plus' color='#fff' />
					<FileFieldController
						name='media'
						control={control}
						accept='image/*'
					/>
				</div>
			)}

			{error && (
				<div
					className={cn(
						'absolute z-20 left-[50%] top-[50%] transform -translate-x-[50%] -translate-y-[50%] p-2 opacity-50 rounded-xl',
						error ? 'bg-red' : 'bg-dark'
					)}
				>
					<Icon size={24} icon='refresh' color='#fff' />
					<FileFieldController
						name='media'
						control={control}
						accept='image/*'
					/>
				</div>
			)}

			{isLoading && (
				<div className='absolute z-20 left-[50%] top-[50%] transform -translate-x-[50%] -translate-y-[50%] p-2 opacity-50 rounded-xl bg-dark'>
					<div className='loader' />
				</div>
			)}

			{(photoFromStorage || media) && (
				<div className='absolute top-0 left-0 bottom-0 right-0 z-20'>
					<FileFieldController
						name='media'
						control={control}
						accept='image/*'
					/>
				</div>
			)}
		</div>
	)
}

export default Photo
