import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SettingsScreen } from '@/components/ui'

import { useTelegram } from '@/hooks'

const Settings: FC = () => {
	const { tg } = useTelegram()
	const navigate = useNavigate()

	const onBack = () => {
		navigate('/payment')
	}

	useEffect(() => {
		tg.BackButton.onClick(onBack)

		return () => {
			tg.BackButton.offClick(onBack)
		}
	}, [])

	return <SettingsScreen disabledActions noRegister />
}

export default Settings
