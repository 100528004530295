import cn from 'clsx'
import { FC } from 'react'

import { BottomSheet, Button, Icon, Typography } from '@/components/ui'

import { useTelegram } from '@/hooks'

import { IPaymentScreen } from './payment-screen.interface'

const PaymentScreen: FC<IPaymentScreen> = ({
	paymentInfo,
	setPaymentInfo,
	currency,
	showModal,
	setShowModal,
	isStar
}) => {
	const { tg } = useTelegram()

	const subscriptionOptions = [
		{
			key: '2_months',
			period: '2 месяца',
			price: currency === 'rub' ? '1000 ₽' : '$10',
			subtitle: `${currency === 'rub' ? '500 ₽' : '$5'}/мес`
		},
		{
			key: '4_months',
			period: '4 месяца',
			subtitle: 'Экономия 25%',
			price: currency === 'rub' ? '1500 ₽' : '$15'
		},
		{
			key: '6_months',
			period: '6 месяцев',
			subtitle: 'Экономия 33%',
			price: currency === 'rub' ? '2000 ₽' : '$20'
		}
	]

	const paymentMethods = [
		{ key: 'smartglocal', value: 'Российские карты' },
		{ key: 'stripe', value: 'Зарубежные карты' }
		// { key: 'paypal', value: 'PayPal' }
	]

	const items = [
		'<b>Доступ</b> к закрытому сообществу амбициозных, интересных женщин.',
		'<b>Знакомства</b> по локации: находи подруг поблизости или в 40+ странах.',
		'<b>Сертификат</b> участницы Frienda.',
		isStar
			? '<b>Доступ</b> к видео «Позитивного усложнения жизни» на 12 месяцев.'
			: '<b>Шанс</b> быть среди первых, кто создаёт новую культуру женской дружбы.'
	]

	return (
		<div className='flex flex-col gap-6 h-full overflow-y-hidden'>
			<div className='flex flex-col gap-2'>
				<svg
					className='mx-auto'
					xmlns='http://www.w3.org/2000/svg'
					width='141'
					height='38'
					viewBox='0 0 141 38'
					fill='none'
				>
					<g clipPath='url(#clip0_5968_52896)'>
						<path
							d='M2.09835 12.9501C2.67179 19.0751 9.82266 21.0274 11.6704 27.4207C13.1898 32.6714 9.48938 35.2148 11.2097 36.9635C13.7191 39.5119 23.6196 36.4318 26.6387 29.7405C28.4963 25.6224 27.1092 22.314 26.6583 21.4744C24.8743 18.1362 24.3891 17.0135 18.7527 11.1071C11.2979 3.303 7.56809 -0.596556 6.23498 0.0740705C4.95086 0.719856 5.00968 4.94231 8.16115 8.49415C8.45522 8.82696 8.70517 9.09523 8.95024 9.34359C9.89129 10.3073 10.5578 10.8935 11.2881 11.5641C11.2881 11.5641 11.8125 12.041 13.4839 13.6952C16.4932 16.6658 18.6644 19.3483 19.8358 20.8883C20.8014 22.165 21.4728 23.6155 21.6983 25.0909C21.9924 27.0332 21.3552 27.9224 20.9043 27.977C20.3848 28.0416 19.8848 28.0466 17.0176 23.9135C15.9099 22.314 14.8709 21.1565 12.7977 18.8367C11.4645 17.3464 10.5775 16.3976 9.88148 15.6773C9.33745 15.111 8.45522 14.187 6.69567 12.3689C4.62736 10.2328 3.58341 9.16475 3.1276 9.23431C2.23068 9.36347 1.96111 11.3803 2.10815 12.9501H2.09835Z'
							fill='#F0A4B7'
						/>
						<path
							d='M25.9818 12.9501C25.4084 19.0751 18.2575 21.0274 16.4098 27.4207C14.8904 32.6714 18.5908 35.2148 16.8705 36.9635C14.3611 39.5119 4.46063 36.4318 1.44147 29.7405C-0.416089 25.6224 0.970956 22.314 1.42187 21.4744C3.20591 18.1362 3.69113 17.0135 9.32754 11.1071C16.7823 3.303 20.5121 -0.596556 21.8452 0.0740705C23.1293 0.719856 23.0705 4.94231 19.919 8.49415C19.625 8.82696 19.375 9.09523 19.1299 9.34359C18.1889 10.3073 17.5224 10.8935 16.7921 11.5641C16.7921 11.5641 16.2677 12.041 14.5963 13.6952C11.587 16.6658 9.41576 19.3483 8.24434 20.8883C7.27881 22.165 6.60735 23.6155 6.38191 25.0909C6.08781 27.0332 6.72498 27.9224 7.17589 27.977C7.69543 28.0416 8.19534 28.0466 11.0626 23.9135C12.1702 22.314 13.2093 21.1565 15.2825 18.8367C16.6156 17.3464 17.5027 16.3976 18.1987 15.6773C18.7427 15.111 19.625 14.187 21.3845 12.3689C23.4528 10.2328 24.4968 9.16475 24.9526 9.23431C25.8495 9.36347 26.1191 11.3803 25.972 12.9501H25.9818Z'
							fill='#EF3B35'
						/>
						<path
							d='M38.1967 13.3723C36.9739 14.5467 36.3613 16.1662 36.3613 18.2348L36.3291 19.0413H33.4844V23.2086H36.3305V35.1948H41.3283V23.2086H46.0092V19.0413H41.3283V18.4914C41.3283 17.6299 41.5543 16.9936 42.0092 16.5864C42.4617 16.178 43.1958 15.9737 44.2063 15.9737C44.7763 15.9737 45.3762 16.0379 46.0092 16.1675V11.9674C45.1873 11.7318 44.28 11.6113 43.2899 11.6113C41.1176 11.6113 39.4218 12.1979 38.198 13.3723H38.1967Z'
							fill='#202022'
						/>
						<path
							d='M54.1774 20.0571C53.3126 20.9513 52.6792 22.3143 52.28 24.1433V19.0385H47.4102V35.1921H52.4378V29.6028C52.4378 27.728 52.8226 26.3036 53.5916 25.3229C54.3606 24.3436 55.5794 23.8539 57.2443 23.8539H57.7819C57.9718 23.8539 58.1603 23.8657 58.3519 23.8867V18.7819C58.0776 18.761 57.814 18.7387 57.5608 18.7178C56.1689 18.7178 55.0405 19.1655 54.1774 20.0571Z'
							fill='#202022'
						/>
						<path
							d='M64.8833 11.562C64.1984 10.9493 63.339 10.6416 62.3051 10.6416C61.2711 10.6416 60.4127 10.9479 59.7264 11.562C59.0415 12.1747 58.6992 12.9563 58.6992 13.9042C58.6992 14.8521 59.0415 15.6063 59.7264 16.2295C60.4117 16.854 61.2711 17.1656 62.3051 17.1656C63.339 17.1656 64.1971 16.854 64.8833 16.2295C65.5696 15.6049 65.9109 14.8286 65.9109 13.9042C65.9109 12.9799 65.5669 12.176 64.8833 11.562Z'
							fill='#202022'
						/>
						<path
							d='M64.8696 19.041H59.7773V35.1944H64.8696V19.041Z'
							fill='#202022'
						/>
						<path
							d='M73.0234 22.7886C73.6138 22.401 74.3092 22.2059 75.1106 22.2059C75.9118 22.2059 76.5967 22.3944 77.1654 22.7715C77.7355 23.1486 78.1464 23.6919 78.3983 24.4029H71.6622C71.9791 23.7142 72.4312 23.1748 73.022 22.7872H73.0234V22.7886ZM78.131 29.9763C77.8038 30.3966 77.3825 30.7147 76.8657 30.9295C76.3485 31.1442 75.7644 31.2528 75.1106 31.2528C74.3299 31.2528 73.645 31.08 73.0542 30.7357C72.4637 30.3913 72.0112 29.8847 71.6947 29.2169C71.4298 28.6592 71.2771 28.0007 71.2345 27.2452H83.4905C83.5525 25.5 83.2527 23.9878 82.5899 22.706C81.9254 21.4256 80.9511 20.4398 79.665 19.7498C78.3792 19.0611 76.8811 18.7168 75.175 18.7168C73.4692 18.7168 71.9117 19.0729 70.574 19.7825C69.235 20.4921 68.2024 21.4845 67.475 22.7545C66.7472 24.0258 66.3945 25.4568 66.4149 27.0515C66.4149 28.688 66.7729 30.1531 67.4904 31.4453C68.2064 32.7375 69.235 33.7391 70.574 34.4501C71.9131 35.1596 73.4574 35.5158 75.2074 35.5158C76.788 35.5158 78.2124 35.225 79.4778 34.6439C80.7428 34.0623 81.7653 33.2429 82.5457 32.1889C83.3264 31.135 83.7685 29.9174 83.873 28.5387H78.7188C78.6569 29.0769 78.4606 29.5573 78.1347 29.9763H78.1324H78.131Z'
							fill='#202022'
						/>
						<path
							d='M95.5646 18.7178C94.2992 18.7178 93.2191 19.015 92.3231 19.6068C91.4275 20.1985 90.7466 21.0443 90.2824 22.1415L90.2503 19.0399H85.2227V35.1932H90.2503V26.6977C90.2503 25.5992 90.5396 24.722 91.1201 24.0635C91.6992 23.4075 92.4849 23.0776 93.476 23.0776C94.3629 23.0776 95.0464 23.346 95.5311 23.8841C96.0157 24.4222 96.2585 25.1659 96.2585 26.1124V35.1909H101.319V25.1122C101.319 23.1313 100.802 21.5693 99.7689 20.4264C98.735 19.2847 97.334 18.7139 95.5632 18.7139L95.5646 18.7178Z'
							fill='#202022'
						/>
						<path
							d='M115.44 29.2169C115.091 29.8415 114.612 30.3206 114.002 30.6545C113.39 30.9897 112.695 31.1546 111.914 31.1546C111.134 31.1546 110.469 30.9871 109.859 30.6545C109.248 30.322 108.767 29.8415 108.421 29.2169C108.072 28.5925 107.909 27.892 107.931 27.1182C107.931 26.3432 108.099 25.644 108.436 25.0195C108.774 24.395 109.247 23.9106 109.861 23.565C110.472 23.2206 111.158 23.0478 111.915 23.0478C112.673 23.0478 113.39 23.2206 114.003 23.565C114.614 23.9093 115.095 24.395 115.441 25.0195C115.79 25.644 115.963 26.3445 115.963 27.1182C115.963 27.892 115.79 28.5937 115.441 29.2169H115.44ZM115.805 22.109H115.772C115.267 21.0315 114.55 20.1989 113.622 19.6045C112.693 19.0127 111.608 18.7155 110.365 18.7155C108.931 18.7155 107.63 19.0808 106.459 19.8139C105.289 20.5471 104.372 21.5539 103.707 22.8344C103.043 24.1161 102.711 25.5432 102.711 27.1143C102.711 28.6854 103.043 30.1465 103.707 31.427C104.372 32.7087 105.288 33.7103 106.459 34.4317C107.629 35.153 108.931 35.5143 110.365 35.5143C111.609 35.5143 112.693 35.2171 113.622 34.6254C114.55 34.0337 115.265 33.1879 115.772 32.0908H115.805V35.1923H120.864V11.9336H115.805V22.109Z'
							fill='#202022'
						/>
						<path
							d='M135.077 29.2169C134.728 29.8415 134.248 30.3206 133.638 30.6545C133.027 30.9897 132.332 31.1546 131.551 31.1546C130.77 31.1546 130.106 30.9871 129.496 30.6545C128.885 30.322 128.404 29.8415 128.058 29.2169C127.709 28.5925 127.546 27.892 127.568 27.1182C127.568 26.3432 127.736 25.644 128.073 25.0195C128.411 24.395 128.883 23.9106 129.497 23.5649C130.109 23.2206 130.795 23.0478 131.552 23.0478C132.31 23.0478 133.027 23.2206 133.64 23.5649C134.252 23.9093 134.732 24.395 135.078 25.0195C135.427 25.644 135.6 26.3444 135.6 27.1182C135.6 27.892 135.427 28.5937 135.078 29.2169H135.077ZM135.441 19.0415V22.1104H135.409C134.903 21.0328 134.186 20.2002 133.258 19.6058C132.33 19.014 131.245 18.7168 130.001 18.7168C128.568 18.7168 127.265 19.0821 126.096 19.8152C124.926 20.5484 124.008 21.5552 123.344 22.8357C122.68 24.1174 122.348 25.5445 122.348 27.1156C122.348 28.6867 122.68 30.1478 123.344 31.4283C124.008 32.71 124.925 33.7116 126.096 34.4328C127.265 35.1545 128.568 35.5158 130.001 35.5158C131.246 35.5158 132.33 35.2186 133.258 34.6269C134.186 34.0348 134.902 33.1892 135.409 32.092H135.441V35.1938H140.501V19.0428H135.441V19.0415Z'
							fill='#202022'
						/>
					</g>
					<defs>
						<clipPath id='clip0_5968_52896'>
							<rect
								width='140'
								height='38'
								fill='white'
								transform='translate(0.5)'
							/>
						</clipPath>
					</defs>
				</svg>
				<Typography
					variant='text'
					className='w-full text-black text-center leading-[22px] tracking-[0.3px]'
				>
					Создавай дружбу с теми, кто <br /> вдохновляет тебя на большее!
				</Typography>
			</div>

			<div className='flex flex-col rounded-xl overflow-hidden'>
				{subscriptionOptions.map((item, index) => (
					<label
						key={index}
						className={cn(
							'flex items-center justify-between px-4 py-3 bg-white checkbox',
							paymentInfo.subscription === item.key && 'active',
							index !== subscriptionOptions.length - 1 &&
								'border-b border-[rgba(0,0,0,0.15)]'
						)}
					>
						<div className='flex gap-3 items-center'>
							<input
								type='checkbox'
								id={item.key}
								checked={paymentInfo.subscription === item.key}
								onChange={() => {
									tg.HapticFeedback.selectionChanged()
									setPaymentInfo(prevValue => ({
										...prevValue,
										subscription: item.key
									}))
								}}
							/>
							<span className='custom-checkbox'></span>
							<div className='flex flex-col'>
								<Typography variant='text-bold' className='leading-[22px]'>
									{item.period}
								</Typography>
								<Typography
									variant='text-mini'
									className='text-dark-gray leading-[18px]'
								>
									{item.subtitle}
								</Typography>
							</div>
						</div>
						<Typography variant='text'>{item.price}</Typography>
					</label>
				))}
			</div>

			<div className='flex flex-col gap-3 ml-4'>
				<Typography
					variant='text-mini'
					className='text-black w-full leading-[18px] tracking-[0.3px]'
				>
					Ты получаешь:
				</Typography>
				<div className='flex flex-col gap-3'>
					{items.map((item, index) => (
						<div key={index} className='flex gap-2'>
							<Icon
								size={16}
								icon='check'
								color='#202022'
								className='flex-shrink-0'
							/>
							<Typography
								variant='text-mini'
								className='text-black w-full leading-[18px] tracking-[0.3px]'
								dangerouslySetInnerHTML={{ __html: item }}
							/>
						</div>
					))}
				</div>
			</div>

			<BottomSheet isOpen={showModal} onClose={() => setShowModal(!showModal)}>
				<div className='flex flex-col gap-2 items-center'>
					<Typography variant='h3' className='leading-[28px]'>
						Выбери способ оплаты
					</Typography>
					<Typography
						variant='text'
						className='leading-[22px] tracking-[0.2px] text-center'
					>
						Удобная оплата в пару кликов — <br /> выбери метод.
					</Typography>
				</div>
				<div className='flex flex-col gap-2'>
					{paymentMethods.map(item => (
						<Button
							key={item.key}
							disabled={paymentInfo.method && paymentInfo.method !== item.key}
							loading={paymentInfo.method === item.key}
							onClick={() => {
								tg.HapticFeedback.notificationOccurred('success')
								setPaymentInfo(prevValue => ({
									...prevValue,
									method: item.key
								}))
							}}
						>
							{item.value}
						</Button>
					))}
				</div>
			</BottomSheet>
		</div>
	)
}

export default PaymentScreen
