import { useAtomValue } from 'jotai/index'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Description } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { countCharacters } from '@/utils/countCharacters'

import AuthLayout from '../AuthLayout'

const AuthenticDescription: FC = () => {
	const { tg } = useTelegram()
	const {
		uploadDescription,
		isUploadDescriptionSuccess,
		isUploadDescriptionLoading,
		isUploadDescriptionError
	} = useProfile()
	const { event, getTimeSpent } = useAmplitude()
	const navigate = useNavigate()
	const profile = useAtomValue(profileAtom)
	const [isError, setIsError] = useState(false)
	const [text, setText] = useState(
		profile?.description && profile.description.authentic?.text
			? profile.description.authentic?.text
			: ''
	)

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_active: false,
			text: 'Загрузка'
		})
		uploadDescription({
			authentic: {
				text
			}
		})
	}

	useEffect(() => {
		if (isUploadDescriptionSuccess) {
			const time = getTimeSpent()
			event('description_entered', {
				number_of_characters: countCharacters(text),
				duration: time,
				flow: 'registration',
				personality_side: 'authentic'
			})
			navigate('/auth/face-liveness/start')
			tg.HapticFeedback.notificationOccurred('success')
		}
		if (isUploadDescriptionError) {
			tg.MainButton.hideProgress()
			setIsError(true)
			tg.MainButton.setParams({
				color: '#EF5652',
				is_active: true,
				text: 'Продолжить'
			})
		}
	}, [isUploadDescriptionLoading])

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.BackButton.onClick(() => navigate('/auth/authentic/photo'))
	}, [])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)
		setIsError(false)

		tg.MainButton.setParams({
			color: !!text ? '#EF5652' : '#F1BBBD',
			is_visible: true,
			is_active: !!text,
			text: 'Продолжить'
		})

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [text])

	return (
		<AuthLayout title='Какая ты в жизни'>
			<Description
				type='authentic'
				isError={isError}
				text={text}
				setText={setText}
			/>
		</AuthLayout>
	)
}

export default AuthenticDescription
