import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	IApiError,
	IAuthFormData,
	IDescription,
	ILivenessFinishSessionRequest,
	ILivenessStartSessionRequest,
	IPhotoRequest,
	IProfile,
	IProfileRequest
} from '@/shared/types/auth.interface'

import { AuthService } from '@/services/auth/auth.service'

import { useAmplitude, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'

export const useProfile = (isInitProfile?: boolean, friend_id?: number) => {
	const { user } = useTelegram()
	const { event } = useAmplitude()
	const navigate = useNavigate()
	const user_id = friend_id || user?.id
	const [profile, setProfile] = useAtom(profileAtom)

	const {
		data: initData,
		isLoading: isInitProfileLoading,
		refetch: refetchInitProfile,
		isSuccess: isInitProfileSuccess,
		isError: isInitProfileError,
		error: errorInitProfile
	} = useQuery<IProfileRequest, IApiError>(
		['initProfile'],
		() => AuthService.initProfile(user_id),
		{
			enabled: !!isInitProfile,
			retry: 0,
			onSuccess: data => {
				setProfile(data.data)
			},
			onError: error => {
				const data = error?.response?.data?.data
				const flow = error?.response?.data?.error
				const welcome = error?.response?.data?.welcome
				const prefill = error?.response?.data?.prefill

				if (prefill && flow === 'registration_flow') {
					if (welcome) {
						setProfile({
							...data,
							name: prefill.name || '',
							address: prefill.address || ''
						})
					} else {
						setProfile({
							...data,
							address: prefill.address || ''
						})
					}
				} else if (data && flow !== 'registration_flow') {
					setProfile(data)
				}
			}
		}
	)

	const {
		data,
		isLoading: isGetProfileLoading,
		isSuccess: isGetProfileSuccess
	} = useQuery<IProfileRequest, IApiError>(
		['getProfile', user_id],
		() => AuthService.getProfile(user_id),
		{
			enabled: !!friend_id,
			retry: 0
		}
	)

	const {
		data: uploadProfileData,
		mutate: uploadProfile,
		isSuccess: isUploadProfileSuccess,
		isLoading: isUploadProfileLoading,
		isError: isUploadProfileError,
		error: errorUploadProfile
	} = useMutation<IProfileRequest, IApiError, Partial<IAuthFormData>>(
		['uploadProfile'],
		(data: Partial<IAuthFormData>) => AuthService.uploadProfile(user_id, data),
		{
			onSuccess: async data => {
				setProfile({
					...profile,
					...data.data
				})
			}
		}
	)

	const { mutate: deleteProfile } = useMutation(
		['deleteProfile'],
		() => AuthService.deleteProfile(user_id),
		{
			onSuccess: async () => {
				event('account_deleted')
				setProfile(null)
				Telegram.WebApp.CloudStorage.removeItem('isFirstLike')
				localStorage.clear()
				navigate(0)
			}
		}
	)

	const {
		mutate: uploadMedia,
		isSuccess: isUploadMediaSuccess,
		isLoading: isUploadMediaLoading,
		error: errorUploadMedia,
		isError: isUploadMediaError,
		data: uploadMediaData
	} = useMutation<IPhotoRequest, IApiError, FormData>(
		['uploadMedia'],
		(formData: FormData) => AuthService.uploadMedia(user_id, formData),
		{
			onSuccess: async data => {
				setProfile({ ...profile, media: data.data.media })
			}
		}
	)

	const {
		mutate: uploadDescription,
		isSuccess: isUploadDescriptionSuccess,
		isLoading: isUploadDescriptionLoading,
		isError: isUploadDescriptionError
	} = useMutation<IProfileRequest, IApiError, Partial<IDescription>>(
		['uploadDescription'],
		(data: IDescription) => AuthService.uploadDescription(user_id, data),
		{
			onSuccess: async data => {
				setProfile({ ...profile, description: data.data.description })
			}
		}
	)

	// TODO: после того как убери update flow перенести в хук useAuth
	const {
		mutate: livenessFinishSession,
		isLoading: isLivenessFinishSessionLoading,
		isError: isLivenessFinishSessionError,
		error: errorLivenessFinishSession,
		isSuccess: isLivenessFinishSessionSuccess
	} = useMutation<ILivenessFinishSessionRequest, IApiError, string>(
		['livenessFinishSession'],
		(sessionId: string) => AuthService.livenessFinishSession(sessionId)
	)

	// TODO: после того как убери update flow перенести в хук useAuth
	const {
		mutate: livenessStartSession,
		isLoading: isLivenessStartSessionLoading,
		isError: isLivenessStartSessionError,
		isSuccess: isLivenessStartSessionSuccess
	} = useMutation<ILivenessStartSessionRequest, IApiError>(
		['livenessStartSession'],
		() => AuthService.livenessStartSession(),
		{
			onSuccess: data => {
				localStorage.setItem('sessionId', data.session_id)
			}
		}
	)

	return useMemo(
		() => ({
			initProfile: initData?.data as IProfile,
			profile: data?.data as IProfile,
			livenessStartSession,
			isLivenessStartSessionLoading,
			isLivenessStartSessionError,
			isLivenessStartSessionSuccess,
			livenessFinishSession,
			isLivenessFinishSessionError,
			errorLivenessFinishSession,
			errorUploadMedia,
			uploadDescription,
			isUploadDescriptionSuccess,
			isUploadDescriptionLoading,
			isUploadDescriptionError,
			isLivenessFinishSessionSuccess,
			uploadProfileData,
			isLivenessFinishSessionLoading,
			isGetProfileSuccess,
			isUploadMediaLoading,
			uploadMediaData,
			isGetProfileLoading,
			isUploadProfileError,
			isUploadMediaError,
			errorUploadProfile,
			deleteProfile,
			isUploadProfileSuccess,
			isUploadProfileLoading,
			isUploadMediaSuccess,
			uploadProfile,
			uploadMedia,
			isInitProfileLoading,
			refetchInitProfile,
			isInitProfileSuccess,
			isInitProfileError,
			errorInitProfile
		}),
		[
			isInitProfileLoading,
			isInitProfileSuccess,
			isInitProfileError,

			isUploadDescriptionLoading,
			isLivenessStartSessionLoading,
			isUploadProfileLoading,
			isLivenessFinishSessionLoading,
			isUploadMediaLoading,
			isGetProfileLoading
		]
	)
}
