import { ISearchRequest } from '@/shared/types/search.interface'

import { request } from '@/services/api/request.api'

export const SearchService = {
	async search({ user_id, view }) {
		return request<ISearchRequest>({
			url: `/search/${user_id}?view=${view}`,
			method: 'POST'
		})
	},
	async feedback({
		search_id,
		to_id,
		button
	}: {
		search_id: string
		to_id: number
		button: string
	}) {
		return request<{
			is_match: boolean
		}>({
			url: '/feedback',
			method: 'POST',
			data: {
				search_id,
				to_id,
				button
			}
		})
	}
}
