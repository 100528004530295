import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { FC, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ButtonLayout, Typography } from '@/components/ui'

import { useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'

import { Divider, Item } from './components'
import { ISettingsScreen } from './settings-screen.interface'

const SettingsScreen: FC<ISettingsScreen> = ({
	disabledActions,
	noRegister
}) => {
	const navigate = useNavigate()
	const profile = useAtomValue(profileAtom)
	const { tg } = useTelegram()
	const { deleteProfile } = useProfile()

	const actions = [
		!noRegister && {
			icon: 'subscription',
			text: 'Доступ',
			subtext: `активен до ${new Date(profile?.sub_until).toLocaleDateString(
				'ru-RU'
			)}`,
			onClick: () => {}
		},
		{
			icon: 'location',
			text: 'Город проживания',
			onClick: () => navigate('/settings/edit-address')
		},
		{
			icon: 'account',
			text: 'Контактная информация',
			onClick: () => navigate('/settings/edit-contacts')
		},
		{
			icon: 'emoji',
			text: 'Эмодзи',
			onClick: () => navigate('/settings/edit-emoji')
		},
		{
			icon: 'photo',
			text: 'Фотографии',
			onClick: () => navigate('/settings/edit-media/beautiful')
		},
		{
			icon: 'description',
			text: 'Описания',
			onClick: () => navigate('/settings/edit-description/beautiful')
		},
		!noRegister && {
			icon: 'certificate',
			text: 'Сертификат',
			onClick: () => navigate('/settings/certificate')
		}
	].filter(Boolean)

	const logout = () => {
		tg.showPopup(
			{
				title: 'Удаление профиля',
				message: 'Удалив профиль вы безвозвратно потеряете данные',
				buttons: [
					{ id: 'cancel', type: 'cancel', text: 'Отмена' },
					{ id: 'delete', type: 'destructive', text: 'Удалить' }
				]
			},
			buttonId => {
				if (buttonId === 'delete') {
					deleteProfile()
				}
			}
		)
	}

	const handleOpenSupport = () => {
		tg.openTelegramLink('https://t.me/frienda_cc_bot')
	}

	useEffect(() => {
		// @ts-ignore
		tg.SecondaryButton.setParams({
			text: 'Удалить профиль',
			color: '#F2F2F7',
			is_active: true,
			position: 'bottom',
			text_color: '#EF5652'
		})
		tg.MainButton.setParams({
			text: 'Написать в поддержку',
			color: '#FFFFFF',
			is_active: true,
			text_color: '#000000'
		})

		tg.MainButton.hideProgress()
		tg.MainButton.show()
		// @ts-ignore
		tg.SecondaryButton.show()

		tg.MainButton.onClick(handleOpenSupport)
		// @ts-ignore
		tg.SecondaryButton.onClick(logout)

		return () => {
			tg.MainButton.offClick(handleOpenSupport)
			// @ts-ignore
			tg.SecondaryButton.hide()
			tg.MainButton.hide()
			tg.MainButton.setParams({
				text_color: '#fff'
			})
		}
	}, [])

	return (
		<ButtonLayout>
			<div className='flex flex-col items-center gap-3'>
				<Typography variant='text-bold' className='w-full leading-[28px]'>
					Настройки
				</Typography>
				<div
					className={cn(
						'flex flex-col bg-white rounded-xl w-full',
						disabledActions && 'opacity-50'
					)}
				>
					{actions.map((action, index) => (
						<Fragment key={index}>
							<Item
								icon={action.icon}
								subtext={action?.subtext}
								text={action.text}
								disabled={disabledActions}
								onClick={action.onClick}
							/>
							{index !== actions.length - 1 && <Divider />}
						</Fragment>
					))}
				</div>
			</div>
		</ButtonLayout>
	)
}

export default SettingsScreen
