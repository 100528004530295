import cn from 'clsx'
import { FC, useEffect, useState } from 'react'

import { Card, CardEnd } from '@/components/ui'

import { useTelegram } from '@/hooks'

import { ISlider } from './slider.interface'

const Slider: FC<ISlider> = ({
	cards,
	searchId,
	setIsFirstCardLoading,
	isFirstCardLoading,
	setCards
}) => {
	const [slideIndex, setSlideIndex] = useState(0)
	const { tg } = useTelegram()
	const [currentIndex, setCurrentIndex] = useState(0)
	const [distance, setDistance] = useState(0)
	const [onTouch, setOnTouch] = useState(false)
	const [touchStartY, setTouchStartY] = useState(0)
	const [touchStartX, setTouchStartX] = useState(0)
	const [matchCards, setMatchCards] = useState<string[]>([])
	const [likedCards, setLikedCards] = useState<string[]>([])
	const cardHeight = window.innerHeight - (tg.platform === 'ios' ? 73 : 57)
	const [lastTouchTime, setLastTouchTime] = useState(0)
	const [isMouseClick, setIsMouseClick] = useState(false)

	const removeSlide = (user_id: string) => {
		setCards(prevState => prevState.filter(card => card.user_id !== user_id))
		if (
			cards.findIndex(card => card.user_id === user_id) ===
			cards.length - 1
		) {
			const newIndex = currentIndex - 1
			setCurrentIndex(newIndex)
			setOnTouch(true)
			setDistance(-cardHeight * newIndex)
		}
	}

	const onMouseOut = () => {
		setIsMouseClick(false)
		setOnTouch(false)
		setDistance(-cardHeight * currentIndex)
	}

	const handleTouchStart = e => {
		if (!cards.length) return

		const currentY = e?.button === 0 ? e?.clientY : e?.touches?.[0]?.clientY
		const currentX = e?.button === 0 ? e?.clientX : e?.touches?.[0]?.clientX
		if (e?.button === 0) setIsMouseClick(true)
		setOnTouch(true)
		setLastTouchTime(Date.now())
		setTouchStartY(currentY)
		setTouchStartX(currentX)
	}

	const handleTouchMove = e => {
		if (!cards.length) return

		const currentY =
			e?.button === 0 && isMouseClick ? e?.clientY : e?.touches?.[0]?.clientY
		const currentX =
			e?.button === 0 && isMouseClick ? e?.clientX : e?.touches?.[0]?.clientX
		const deltaY = currentY - touchStartY
		const deltaX = currentX - touchStartX

		if (
			(Math.abs(deltaX) > 15 && Math.abs(deltaY) < Math.abs(deltaX)) ||
			Math.abs(deltaX) > 100
		)
			return

		if (!currentY || Math.abs(currentY - touchStartY) < 15) return

		if (currentIndex === 0 && deltaY > 50) {
			setDistance(50 + (deltaY - 50) / 5)
		} else if (currentIndex === cards.length - 1 && deltaY < -50) {
			setDistance(
				-((cards.length - 1) * cardHeight + 50 + (Math.abs(deltaY) - 50) / 5)
			)
		} else {
			setDistance(currentIndex * -cardHeight + deltaY)
		}
	}

	const handleTouchEnd = () => {
		setOnTouch(false)
		setIsMouseClick(false)
		const newDistance = distance - currentIndex * -cardHeight
		const deltaTime = Date.now() - lastTouchTime
		const velocity = newDistance / deltaTime
		const minVelocity = ['ios', 'android'].includes(tg.platform) ? 0.6 : 0.2

		if (!currentIndex) {
			if (
				newDistance > 0 ||
				(Math.abs(velocity) < minVelocity &&
					Math.abs(newDistance) <= cardHeight / 4)
			) {
				setDistance(0)
			} else {
				setDistance(-cardHeight)
				setCurrentIndex(1)
			}
		} else if (currentIndex === cards.length - 1) {
			if (
				newDistance < 0 ||
				(Math.abs(velocity) < minVelocity &&
					Math.abs(newDistance) <= cardHeight)
			) {
				setDistance(-cardHeight * currentIndex)
			} else {
				setDistance(-cardHeight * (currentIndex - 1))
				setCurrentIndex(currentIndex - 1)
			}
		} else {
			if (
				Math.abs(velocity) < minVelocity &&
				Math.abs(newDistance) <= cardHeight / 4
			) {
				setDistance(-cardHeight * currentIndex)
			} else {
				if (newDistance > 0) {
					setDistance(-cardHeight * (currentIndex - 1))
					setCurrentIndex(prevSlide => prevSlide - 1)
				} else {
					setDistance(-cardHeight * (currentIndex + 1))
					setCurrentIndex(prevSlide => prevSlide + 1)
				}
			}
		}
	}

	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'ArrowUp') {
			setCurrentIndex(prevIndex => {
				if (prevIndex > 0) {
					const newIndex = prevIndex - 1
					setDistance(-cardHeight * newIndex)
					return newIndex
				}
				return prevIndex
			})
		} else if (event.key === 'ArrowDown') {
			setCurrentIndex(prevIndex => {
				if (prevIndex < cards?.length - 1) {
					const newIndex = prevIndex + 1
					setDistance(-cardHeight * newIndex)
					return newIndex
				}
				return prevIndex
			})
		}
	}

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [cards])

	if (!cards || !cards?.length) return <CardEnd type='community' />

	return (
		<div
			className='h-full w-full bg-dark'
			onMouseDown={handleTouchStart}
			onMouseMove={handleTouchMove}
			onMouseUp={handleTouchEnd}
			onMouseOut={onMouseOut}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			<div
				className='relative h-full w-full'
				style={{
					transform: `translateY(${distance}px)`,
					transition: `transform ${onTouch ? 0 : 180}ms ease-in-out`
				}}
			>
				{cards.map((card, index) => {
					const isPrev = index === currentIndex - 1
					const isCurrent = index === currentIndex
					const isNext = index === currentIndex + 1
					const isDoubleNext = index === currentIndex + 2
					const shouldDisplay =
						isCurrent ||
						((isPrev || isNext || isDoubleNext) && isFirstCardLoading)
					if (shouldDisplay) {
						return (
							<div
								key={card.user_id}
								className={cn('w-full relative')}
								style={{
									top:
										currentIndex > 0
											? cards.filter((_, index) => index < currentIndex - 1)
													.length * cardHeight
											: 0,
									height: `${cardHeight}px`
								}}
							>
								<Card
									card={card}
									searchId={searchId}
									slideIndex={slideIndex}
									setSlideIndex={setSlideIndex}
									removeSlide={removeSlide}
									removeLikedCard={id =>
										setLikedCards(prevState =>
											prevState.filter(item => item !== id)
										)
									}
									addMatchCard={id => setMatchCards([...matchCards, id])}
									isMatch={matchCards.includes(card.user_id)}
									addLikedCard={id => setLikedCards([...likedCards, id])}
									isLikeCard={likedCards.includes(card.user_id)}
									setIsFirstCardLoading={value => {
										if (!index) setIsFirstCardLoading(value)
									}}
									isActive={isCurrent}
								/>
							</div>
						)
					}
				})}
			</div>
		</div>
	)
}

export default Slider
