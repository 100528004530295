import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { WelcomeScreen } from '@/components/ui'

import { useTelegram } from '@/hooks'

const Welcome: FC = () => {
	const { tg } = useTelegram()
	const navigate = useNavigate()

	const onSubmit = () => {
		tg.HapticFeedback.notificationOccurred('success')
		navigate('/payment')
	}

	useEffect(() => {
		tg.BackButton.hide()
		tg.MainButton.hideProgress()
		tg.MainButton.setParams({
			is_visible: true,
			color: '#fff',
			text_color: '#EF3B35',
			is_active: true,
			text: 'Продлить подписку'
		})
		// @ts-ignore
		tg?.setBottomBarColor('#EF3B35')
		tg.setBackgroundColor('#EF3B35')

		tg.MainButton.onClick(onSubmit)

		return () => {
			// @ts-ignore
			tg?.setBottomBarColor('#F2F2F7')
			tg.setBackgroundColor('#F2F2F7')
			tg.MainButton.setParams({
				color: '#EF5652',
				text_color: '#fff'
			})
			tg.MainButton.offClick(onSubmit)
		}
	}, [])

	return <WelcomeScreen />
}

export default Welcome
