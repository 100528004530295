import { useAtomValue } from 'jotai/index'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Typography } from '@/components/ui'

import { useAmplitude, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'

const Welcome: FC = () => {
	const profile = useAtomValue(profileAtom)
	const navigate = useNavigate()
	const { event, getTimeSpent } = useAmplitude()
	const { tg } = useTelegram()

	const onSubmit = () => {
		tg.HapticFeedback.notificationOccurred('success')
		const time = getTimeSpent()
		event('registration_start', {
			duration: time,
			flow: 'updating'
		})
		navigate('/auth/name')
	}

	useEffect(() => {
		tg.SettingsButton.show()
		tg.SettingsButton.onClick(() => navigate('/settings'))
		tg.BackButton.hide()
		tg.MainButton.hideProgress()
		tg.MainButton.setParams({
			is_visible: true,
			color: '#EF5652',
			is_active: true,
			text: 'Обновить профиль'
		})
		tg.MainButton.onClick(onSubmit)

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [])

	return (
		<div className='h-[100vh] flex flex-col justify-center gap-8 items-center bg-light-gray'>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='243'
				height='64'
				viewBox='0 0 243 64'
				fill='none'
			>
				<g clipPath='url(#clip0_6496_23808)'>
					<path
						d='M2.77327 21.8106C3.7686 32.1265 16.1805 35.4145 19.3877 46.1822C22.0249 55.0255 15.602 59.3091 18.588 62.2543C22.9436 66.5463 40.1279 61.3589 45.3683 50.0893C48.5925 43.1535 46.185 37.5814 45.4024 36.1675C42.3058 30.5453 41.4636 28.6544 31.6804 18.7067C18.7411 5.56295 12.2672 -1.00473 9.95328 0.12475C7.72442 1.21239 7.82651 8.32389 13.2966 14.3059C13.807 14.8665 14.2408 15.3183 14.6662 15.7366C16.2996 17.3597 17.4565 18.3469 18.7241 19.4764C18.7241 19.4764 19.6343 20.2796 22.5353 23.0656C27.7586 28.0687 31.5273 32.5866 33.5605 35.1802C35.2364 37.3305 36.4019 39.7734 36.7932 42.2583C37.3036 45.5296 36.1977 47.0272 35.415 47.1192C34.5133 47.2279 33.6455 47.2363 28.6689 40.2754C26.7463 37.5814 24.9428 35.6321 21.3443 31.7249C19.0303 29.215 17.4906 27.617 16.2826 26.4038C15.3383 25.4501 13.807 23.8939 10.7529 20.8318C7.16291 17.2342 5.35092 15.4354 4.55976 15.5525C3.00296 15.7701 2.53507 19.1669 2.79028 21.8106H2.77327Z'
						fill='#F0A4B7'
					/>
					<path
						d='M44.2292 21.8106C43.2339 32.1265 30.822 35.4145 27.6148 46.1822C24.9776 55.0255 31.4005 59.3091 28.4145 62.2543C24.0589 66.5463 6.87452 61.3589 1.63412 50.0893C-1.59007 43.1535 0.817445 37.5814 1.6001 36.1675C4.69668 30.5453 5.53889 28.6544 15.3221 18.7067C28.2614 5.56295 34.7353 -1.00473 37.0492 0.12475C39.278 1.21239 39.176 8.32389 33.7059 14.3059C33.1955 14.8665 32.7616 15.3183 32.3363 15.7366C30.7029 17.3597 29.546 18.3469 28.2784 19.4764C28.2784 19.4764 27.3681 20.2796 24.4672 23.0656C19.2439 28.0687 15.4752 32.5866 13.442 35.1802C11.7661 37.3305 10.6006 39.7734 10.2093 42.2583C9.69884 45.5296 10.8048 47.0272 11.5874 47.1192C12.4892 47.2279 13.3569 47.2363 18.3336 40.2754C20.2562 37.5814 22.0597 35.6321 25.6582 31.7249C27.9722 29.215 29.5119 27.617 30.7199 26.4038C31.6642 25.4501 33.1955 23.8939 36.2496 20.8318C39.8395 17.2342 41.6515 15.4354 42.4427 15.5525C43.9995 15.7701 44.4674 19.1669 44.2122 21.8106H44.2292Z'
						fill='#EF3B35'
					/>
					<path
						d='M65.4312 22.5224C63.3087 24.5004 62.2454 27.228 62.2454 30.712L62.1896 32.0702H57.252V39.0889H62.1919V59.2762H70.8667V39.0889H78.9915V32.0702H70.8667V31.1441C70.8667 29.6932 71.2591 28.6216 72.0486 27.9358C72.834 27.2478 74.1082 26.9038 75.8621 26.9038C76.8516 26.9038 77.8928 27.0119 78.9915 27.2302V20.1564C77.5649 19.7595 75.99 19.5566 74.2716 19.5566C70.501 19.5566 67.5577 20.5445 65.4335 22.5224H65.4312Z'
						fill='#202022'
					/>
					<path
						d='M93.1669 33.7812C91.6658 35.2872 90.5665 37.5826 89.8736 40.6631V32.0656H81.4209V59.2717H90.1474V49.8581C90.1474 46.7005 90.8153 44.3014 92.1501 42.6498C93.4849 41.0005 95.6004 40.1758 98.4902 40.1758H99.4232C99.7529 40.1758 100.08 40.1956 100.413 40.2309V31.6334C99.9366 31.5981 99.4791 31.5606 99.0396 31.5254C96.6235 31.5254 94.665 32.2795 93.1669 33.7812Z'
						fill='#202022'
					/>
					<path
						d='M111.752 19.472C110.564 18.44 109.072 17.9219 107.277 17.9219C105.483 17.9219 103.993 18.4378 102.802 19.472C101.613 20.504 101.019 21.8204 101.019 23.4168C101.019 25.0133 101.613 26.2834 102.802 27.333C103.991 28.3848 105.483 28.9096 107.277 28.9096C109.072 28.9096 110.561 28.3848 111.752 27.333C112.944 26.2812 113.536 24.9736 113.536 23.4168C113.536 21.8601 112.939 20.5062 111.752 19.472Z'
						fill='#202022'
					/>
					<path
						d='M111.725 32.0684H102.887V59.274H111.725V32.0684Z'
						fill='#202022'
					/>
					<path
						d='M125.881 38.3792C126.906 37.7265 128.113 37.3979 129.504 37.3979C130.895 37.3979 132.083 37.7154 133.07 38.3505C134.06 38.9855 134.773 39.9006 135.21 41.098H123.518C124.068 39.9381 124.853 39.0297 125.879 38.377H125.881V38.3792ZM134.746 50.4849C134.179 51.1927 133.447 51.7285 132.55 52.0902C131.653 52.4518 130.639 52.6348 129.504 52.6348C128.149 52.6348 126.96 52.3437 125.935 51.7638C124.91 51.1839 124.124 50.3305 123.575 49.2059C123.115 48.2666 122.85 47.1575 122.776 45.8851H144.049C144.157 42.9458 143.636 40.399 142.486 38.2402C141.332 36.0837 139.641 34.4233 137.409 33.2613C135.177 32.1014 132.577 31.5215 129.616 31.5215C126.655 31.5215 123.952 32.1213 121.63 33.3164C119.306 34.5115 117.513 36.1829 116.251 38.3218C114.987 40.4629 114.375 42.8731 114.411 45.5588C114.411 48.3151 115.032 50.7826 116.277 52.959C117.52 55.1353 119.306 56.8222 121.63 58.0197C123.954 59.2146 126.634 59.8146 129.672 59.8146C132.415 59.8146 134.888 59.3249 137.084 58.346C139.28 57.3666 141.055 55.9865 142.409 54.2114C143.764 52.4363 144.532 50.3857 144.713 48.0637H135.767C135.659 48.97 135.319 49.7793 134.753 50.4849H134.749H134.746Z'
						fill='#202022'
					/>
					<path
						d='M165.008 31.5242C162.812 31.5242 160.937 32.0248 159.382 33.0215C157.828 34.0181 156.646 35.4425 155.84 37.2904L155.784 32.0667H147.058V59.2723H155.784V44.964C155.784 43.1139 156.286 41.6365 157.294 40.5274C158.299 39.4227 159.663 38.867 161.383 38.867C162.922 38.867 164.109 39.3191 164.95 40.2253C165.791 41.1316 166.213 42.3841 166.213 43.9783V59.2683H174.996V42.2937C174.996 38.9574 174.098 36.3268 172.306 34.4018C170.511 32.479 168.079 31.5176 165.006 31.5176L165.008 31.5242Z'
						fill='#202022'
					/>
					<path
						d='M199.506 49.2065C198.901 50.2583 198.068 51.0653 197.01 51.6276C195.948 52.1921 194.741 52.4699 193.386 52.4699C192.032 52.4699 190.878 52.1877 189.82 51.6276C188.759 51.0675 187.924 50.2583 187.323 49.2065C186.717 48.1547 186.435 46.975 186.473 45.6718C186.473 44.3664 186.764 43.1889 187.35 42.1371C187.936 41.0853 188.756 40.2694 189.822 39.6873C190.883 39.1074 192.074 38.8163 193.388 38.8163C194.703 38.8163 195.948 39.1074 197.011 39.6873C198.073 40.2672 198.907 41.0853 199.508 42.1371C200.114 43.1889 200.415 44.3686 200.415 45.6718C200.415 46.975 200.114 48.1569 199.508 49.2065H199.506ZM200.139 37.2353H200.083C199.205 35.4205 197.961 34.0181 196.35 33.017C194.739 32.0203 192.855 31.5198 190.697 31.5198C188.209 31.5198 185.95 32.135 183.917 33.3698C181.887 34.6046 180.294 36.3003 179.142 38.4569C177.988 40.6156 177.412 43.0191 177.412 45.6652C177.412 48.3113 177.988 50.7721 179.142 52.9286C180.294 55.0874 181.885 56.7742 183.917 57.9891C185.948 59.204 188.209 59.8126 190.697 59.8126C192.857 59.8126 194.739 59.312 196.35 58.3154C197.961 57.3189 199.203 55.8944 200.083 54.0466H200.139V59.2703H208.921V20.0977H200.139V37.2353Z'
						fill='#202022'
					/>
					<path
						d='M233.584 49.2059C232.978 50.2578 232.145 51.0648 231.087 51.6271C230.025 52.1916 228.818 52.4694 227.464 52.4694C226.109 52.4694 224.955 52.1872 223.897 51.6271C222.836 51.067 222.001 50.2578 221.4 49.2059C220.794 48.1542 220.512 46.9745 220.55 45.6713C220.55 44.3659 220.841 43.1884 221.427 42.1366C222.013 41.0847 222.834 40.2689 223.899 39.6867C224.96 39.1069 226.151 38.8158 227.466 38.8158C228.781 38.8158 230.025 39.1069 231.089 39.6867C232.152 40.2667 232.984 41.0847 233.585 42.1366C234.191 43.1884 234.492 44.3681 234.492 45.6713C234.492 46.9745 234.191 48.1563 233.585 49.2059H233.584ZM234.216 32.0683V37.237H234.16C233.283 35.4222 232.038 34.0198 230.427 33.0187C228.816 32.022 226.932 31.5215 224.774 31.5215C222.286 31.5215 220.025 32.1367 217.994 33.3715C215.964 34.6063 214.372 36.302 213.219 38.4586C212.066 40.6173 211.489 43.0208 211.489 45.6669C211.489 48.3129 212.066 50.7738 213.219 52.9303C214.372 55.089 215.962 56.7759 217.994 57.9906C220.025 59.206 222.286 59.8146 224.774 59.8146C226.934 59.8146 228.816 59.314 230.427 58.3174C232.038 57.3203 233.281 55.8961 234.16 54.0482H234.216V59.2723H242.998V32.0705H234.216V32.0683Z'
						fill='#202022'
					/>
				</g>
				<defs>
					<clipPath id='clip0_6496_23808'>
						<rect width='243' height='64' fill='white' />
					</clipPath>
				</defs>
			</svg>
			<div className='flex flex-col gap-2 items-center text-center'>
				<Typography variant='h3' className='leading-7'>
					{profile?.name?.split(' ')[0]}, ура! <br />
					Мы обновили приложение
				</Typography>
				<Typography variant='text' className='leading-[22px] text-black'>
					Обнови данные в профиле <br /> и познакомься с новыми <br />
					участницами!
				</Typography>
			</div>
			<Typography
				variant='text-small'
				className='text-dark opacity-50 text-center max-w-[280px] absolute bottom-6 leading-[18px] tracking-[0.3px]'
			>
				<span>Продолжая использование я соглашаюсь с </span>
				<a href='https://wowmalika.com/terms' target='_blank'>
					пользовательским соглашением
				</a>
				<span> и </span>
				<a href='https://wowmalika.com/privacy' target='_blank'>
					политикой конфиденциальности
				</a>
			</Typography>
		</div>
	)
}

export default Welcome
