import { Welcome } from '@/components/screens/unauthorized/server-error'

import { IRoute } from '../navigation.types'

const serverErrorRoutes: IRoute[] = [
	{
		path: '/welcome',
		component: Welcome
	}
]

export default serverErrorRoutes
