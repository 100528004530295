import {
	Payment,
	Settings,
	Welcome
} from '@/components/screens/unauthorized/subscription-renewal'

import { IRoute } from '../navigation.types'

const subscriptionRenewalRoutes: IRoute[] = [
	{
		path: '/welcome',
		component: Welcome
	},
	{
		path: '/payment',
		component: Payment
	},
	{
		path: '/settings',
		component: Settings
	}
]

export default subscriptionRenewalRoutes
