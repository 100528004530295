import { Welcome } from '@/components/screens/unauthorized/male-user'

import { IRoute } from '../navigation.types'

const maleUserRoutes: IRoute[] = [
	{
		path: '/welcome',
		component: Welcome
	}
]

export default maleUserRoutes
