import { useAtomValue } from 'jotai/index'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { PaymentScreen } from '@/components/ui'

import { URL } from '@/config/api.config'

import { useAmplitude, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { notifyError } from '@/utils/toastNotifications'

import AuthLayout from '../AuthLayout'

const Payment: FC = () => {
	const { tg } = useTelegram()
	const navigate = useNavigate()
	const [showModal, setShowModal] = useState(false)
	const { event } = useAmplitude()
	const profile = useAtomValue(profileAtom)
	const currency = profile?.country?.en === 'Russia' ? 'rub' : 'usd'
	const [paymentInfo, setPaymentInfo] = useState({
		id: tg.initDataUnsafe.user.id,
		subscription: localStorage.getItem('subscription') || '4_months',
		method: ''
	})

	const onSubmit = () => {
		if (paymentInfo.id) {
			window.location.replace(
				`${URL}pay?tg_id=${paymentInfo.id}&method_type=${paymentInfo.method}&plan=${paymentInfo.subscription}`
			)
			tg.MainButton.hide()
		} else {
			console.error('User ID is undefined')
		}
	}

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.MainButton.show()
		tg.BackButton.show()
		localStorage.setItem('register', 'true')
		tg.BackButton.onClick(() => navigate('/auth/face-liveness/start'))
		tg.MainButton.onClick(() => {
			setShowModal(true)
		})
		tg.MainButton.setParams({
			color: '#EF5652',
			is_active: true,
			text: 'Оплатить'
		})
		if (localStorage.getItem('errorPayment')) {
			event('payment_error', {
				currency: currency.toUpperCase(),
				plan: paymentInfo.subscription,
				payment_system: paymentInfo.method
			})
			notifyError('Возникла ошибка оплаты. Попробуй оплатить еще раз.')
			localStorage.removeItem('errorPayment')
		}
	}, [])

	useEffect(() => {
		localStorage.setItem('subscription', paymentInfo.subscription)

		if (paymentInfo.method) {
			event('payment_started', {
				currency: currency.toUpperCase(),
				plan: paymentInfo.subscription,
				payment_system: paymentInfo.method
			})
			onSubmit()
		}
	}, [paymentInfo])

	useEffect(() => {
		if (showModal) {
			tg.MainButton.hide()
		} else {
			tg.MainButton.show()
		}
	}, [showModal])

	return (
		<AuthLayout>
			<PaymentScreen
				isStar
				currency={currency}
				paymentInfo={paymentInfo}
				setPaymentInfo={setPaymentInfo}
				showModal={showModal}
				setShowModal={setShowModal}
			/>
		</AuthLayout>
	)
}

export default Payment
