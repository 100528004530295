import { Identify, identify, logEvent } from '@amplitude/analytics-browser'
import { useEffect, useRef } from 'react'

export const useAmplitude = () => {
	const startTimeRef = useRef<number | null>(null)

	useEffect(() => {
		startTimeRef.current = Date.now()
	}, [])

	const event = (eventName: string, eventProperties?: Record<string, any>) => {
		logEvent(eventName, eventProperties)
	}

	const setProperties = (value: string, properties: string) => {
		const identifyEvent = new Identify()
		identifyEvent.set(value, properties)
		identify(identifyEvent)
	}

	const getTimeSpent = () => {
		if (startTimeRef.current) {
			return Math.floor((Date.now() - startTimeRef.current) / 1000)
		}
		return 0
	}

	return {
		event,
		setProperties,
		getTimeSpent
	}
}
