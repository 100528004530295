import { IMenuItem } from './menu.interface'

export const menuItems: IMenuItem[] = [
	{
		iconName: 'favorites',
		path: '/friends',
		name: 'Подруги'
	},
	{
		iconName: 'picks',
		path: '/community',
		name: 'Сообщество'
	},
	{
		iconName: 'profile',
		path: '/profile',
		name: 'Профиль'
	}
]
