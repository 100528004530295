import {
	IAuthFormData,
	IDescription,
	ILivenessFinishSessionRequest,
	ILivenessStartSessionRequest,
	IPhotoRequest,
	IProfileRequest
} from '@/shared/types/auth.interface'

import { request } from '@/services/api/request.api'

export const AuthService = {
	async initProfile(user_id: number) {
		return request<IProfileRequest>({
			url: `/profile/${user_id}`,
			method: 'PUT'
		})
	},
	async getProfile(user_id: number) {
		return request<IProfileRequest>({
			url: `/profile/${user_id}`,
			method: 'GET'
		})
	},
	async deleteProfile(user_id: number) {
		return request({
			url: `/profile/${user_id}`,
			method: 'DELETE'
		})
	},
	async uploadProfile(user_id: number, data: Partial<IAuthFormData>) {
		return request<IProfileRequest>({
			url: `/profile/${user_id}/upload`,
			method: 'PUT',
			data
		})
	},
	async uploadMedia(user_id: number, formData: FormData) {
		return request<IPhotoRequest>({
			url: `/profile/${user_id}/media/upload`,
			method: 'POST',
			data: formData
		})
	},
	async uploadDescription(user_id: number, data: IDescription) {
		return request<IProfileRequest>({
			url: `/profile/${user_id}/description/upload`,
			method: 'PUT',
			data
		})
	},
	async livenessStartSession() {
		return request<ILivenessStartSessionRequest>({
			url: '/liveness/create_session',
			method: 'POST'
		})
	},
	async livenessFinishSession(sessionId: string) {
		return request<ILivenessFinishSessionRequest>({
			url: `/liveness/finish_session?session_id=${sessionId}`,
			method: 'POST'
		})
	}
}
