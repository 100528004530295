import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Photo } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'

import EditMediaLayout from '../EditMediaLayout'

const Professional: FC = () => {
	const { tg } = useTelegram()
	const { event, getTimeSpent } = useAmplitude()
	const [durationUser, setDurationUser] = useState(0)
	const [photo, setPhoto] = useState<Blob>(null)
	const [hasPhoto, setHasPhoto] = useState(false)
	const [error, setError] = useState<string>(null)
	const navigate = useNavigate()
	const {
		uploadMedia,
		isUploadMediaError,
		errorUploadMedia,
		isUploadMediaLoading,
		isUploadMediaSuccess
	} = useProfile()

	const handleClick = () => {
		navigate('/settings/edit-media/authentic')
	}

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.MainButton.setParams({
			color: hasPhoto ? '#EF5652' : '#F1BBBD',
			is_visible: true,
			is_active: hasPhoto,
			text: 'Продолжить'
		})
		tg.MainButton.onClick(handleClick)
		tg.BackButton.onClick(() => navigate('/settings/edit-media/beautiful'))

		return () => {
			tg.MainButton.offClick(handleClick)
		}
	}, [hasPhoto])

	useEffect(() => {
		if (isUploadMediaSuccess) {
			const time = getTimeSpent()
			event('photo_uploaded', {
				duration_upload: time - durationUser,
				duration: durationUser,
				personality_side: 'professional',
				flow: 'settings'
			})
			tg.MainButton.setParams({
				color: '#EF5652',
				is_visible: true,
				is_active: true,
				text: 'Продолжить'
			})
			tg.HapticFeedback.notificationOccurred('success')
		}
		if (isUploadMediaError) {
			const error = errorUploadMedia?.response
				? errorUploadMedia.response.data.error
				: ''
			const time = getTimeSpent()
			event('photo_upload_error', {
				duration: time,
				personality_side: 'professional',
				flow: 'settings',
				error_type: error
			})
			tg.MainButton.hideProgress()
			setError(error)
			tg.MainButton.setParams({
				color: '#F1BBBD',
				is_visible: true,
				is_active: false,
				text: 'Продолжить'
			})
		}
	}, [isUploadMediaLoading])

	useEffect(() => {
		if (!photo) return

		const formData = new FormData()
		formData.append('professional', photo)
		const time = getTimeSpent()
		setDurationUser(time)
		uploadMedia(formData)
	}, [photo])

	return (
		<EditMediaLayout
			title='Фото про работу'
			description='Добавь фото, которое показывает тебя в деле: карьера, декрет, гедонизм.'
		>
			<Photo
				type='professional'
				photo={photo}
				setPhoto={setPhoto}
				setHasPhoto={setHasPhoto}
				isLoading={isUploadMediaLoading}
				error={error}
			/>
		</EditMediaLayout>
	)
}

export default Professional
