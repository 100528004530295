import { useAtomValue } from 'jotai/index'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Field } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { countCharacters } from '@/utils/countCharacters'
import { DATE_REGEX } from '@/utils/regex'

import AuthLayout from '../AuthLayout'

const Address: FC = () => {
	const { tg } = useTelegram()
	const { event, getTimeSpent, setProperties } = useAmplitude()
	const profile = useAtomValue(profileAtom)
	const navigate = useNavigate()
	const {
		uploadProfileData,
		uploadProfile,
		isUploadProfileSuccess,
		isUploadProfileError,
		isUploadProfileLoading
	} = useProfile()
	const { control, watch, setValue, setError, clearErrors } = useForm<{
		date: string
	}>({
		mode: 'onChange',
		defaultValues: {
			date: profile?.birthday || ''
		}
	})
	const { date } = watch()

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_visible: true,
			is_active: false,
			text: 'Загрузка'
		})
		uploadProfile({ birthday: date })
	}

	useEffect(() => {
		if (isUploadProfileSuccess) {
			const time = getTimeSpent()
			event('date_of_birth_entered', {
				duration: time,
				flow: 'registration'
			})
			if (uploadProfileData.data.age) {
				setProperties('year_of_birth', uploadProfileData.data.age)
			}
			navigate('/auth/address')
			tg.HapticFeedback.notificationOccurred('success')
		}
		if (isUploadProfileError) {
			tg.MainButton.hideProgress()
			setError('date', {})
			tg.MainButton.setParams({
				color: '#EF5652',
				is_visible: true,
				is_active: true,
				text: 'Продолжить'
			})
		}
	}, [isUploadProfileLoading])

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.BackButton.onClick(() => navigate('/auth/name'))
	}, [])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)

		const disabled = Boolean(!date || countCharacters(date) !== 10)

		tg.MainButton.setParams({
			color: disabled ? '#F1BBBD' : '#EF5652',
			is_visible: true,
			is_active: !disabled,
			text: 'Продолжить'
		})

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [date])

	return (
		<AuthLayout
			title='Дата рождения'
			description='Виден будет только возраст, но точная дата рождения улучшит подбор подруг.'
			footer={{
				icon: 'warning-round',
				title: 'Изменить дату рождения после регистрации нельзя.'
			}}
		>
			<Field
				onKeyDown={e => {
					if (e.key === 'Enter') {
						const target = e.target as HTMLInputElement
						target.blur()
					}
				}}
				inputMode='numeric'
				onChange={e => {
					if (DATE_REGEX.test(e.target.value.replace(/\//g, ''))) {
						clearErrors('date')
						let formattedDate = e.target.value

						if (formattedDate.length <= 8) {
							formattedDate = e.target.value.replace(/(\d{2})(?=\d)/g, '$1/')
						}

						if (formattedDate.endsWith('/')) {
							formattedDate = formattedDate.slice(0, -1)
						}

						setValue('date', formattedDate)
					} else {
						tg.HapticFeedback.notificationOccurred('warning')
						setError('date', {})
						setTimeout(() => {
							clearErrors('date')
						}, 250)
					}
				}}
				control={control}
				name='date'
				placeholder='ДД/ММ/ГГГГ'
			/>
		</AuthLayout>
	)
}

export default Address
