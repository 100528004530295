import cn from 'clsx'
import { useAtom } from 'jotai'
import { FC, PropsWithChildren, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAmplitude, useTelegram } from '@/hooks'
import { startParamAtom } from '@/store'

import BottomMenu from './bottom-menu/BottomMenu'
import { IMainLayout } from './main-layout.interface'

const MainLayout: FC<PropsWithChildren<IMainLayout>> = ({ children }) => {
	const { tg } = useTelegram()
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const { event } = useAmplitude()
	const [startParam, setStartParam] = useAtom(startParamAtom)
	const pageParams = !startParam
		? tg.initDataUnsafe.start_param?.split('_') || []
		: []

	useEffect(() => {
		tg.MainButton.hide()
		localStorage.removeItem('isUploadProfileSuccess')
		localStorage.removeItem('isUploadPhotoSuccess')

		if (pageParams.length) {
			const redirectPage = pageParams[0]
			setStartParam(tg.initDataUnsafe.start_param)

			if (['friend'].includes(redirectPage)) {
				navigate('/friend', {
					state: {
						user_id: pageParams[1]
					}
				})
			}
		}

		if (pathname === '/community') {
			tg.BackButton.hide()
		} else {
			tg.BackButton.show()
		}
		tg.BackButton.onClick(() => navigate('/community'))

		tg.SettingsButton.show()
		tg.SettingsButton.onClick(() => {
			event('open_header_settings', {
				screen: pathname.slice(1)
			})
			navigate('/settings')
		})

		return () => {
			tg.BackButton.offClick(() => navigate('/community'))
		}
	}, [])

	return (
		<div className='w-full h-full bg-light-gray'>
			<div
				className={cn(
					'w-full overflow-hidden relative',
					!['/community', '/profile', '/friends', '/friend'].includes(
						pathname
					) && 'py-2 px-4'
				)}
				style={{
					height: `calc(100vh - ${tg.platform === 'ios' ? '73' : '57'}px)`
				}}
			>
				{children}
			</div>
			<BottomMenu />
		</div>
	)
}

export default MainLayout
