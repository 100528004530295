import { useAtomValue } from 'jotai'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ButtonLayout, Field, Typography } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { ADDRESS_REGEX } from '@/utils/regex'

const EditAddress: FC = () => {
	const navigate = useNavigate()
	const { event, getTimeSpent } = useAmplitude()
	const profile = useAtomValue(profileAtom)
	const {
		uploadProfile,
		isUploadProfileLoading,
		isUploadProfileError,
		isUploadProfileSuccess
	} = useProfile()
	const { tg } = useTelegram()
	const { control, watch, setValue, setError, clearErrors } = useForm<{
		address: string
	}>({
		mode: 'onChange',
		defaultValues: {
			address: profile?.city['ru'] || ''
		}
	})
	const { address } = watch()

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.BackButton.onClick(() => navigate('/settings'))

		return () => {
			tg.MainButton.hide()
		}
	}, [])

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_visible: true,
			is_active: false,
			text: 'Загрузка'
		})
		uploadProfile({ address })
	}

	useEffect(() => {
		if (isUploadProfileSuccess) {
			const time = getTimeSpent()
			event('city_entered', {
				duration: time,
				flow: 'settings'
			})
			tg.MainButton.hide()
			tg.HapticFeedback.notificationOccurred('success')
			navigate('/settings')
		}
		if (isUploadProfileError) {
			tg.MainButton.hideProgress()
			setError('address', {})
			tg.MainButton.setParams({
				color: '#F1BBBD',
				is_visible: true,
				is_active: false,
				text: 'Сохранить'
			})
		}
	}, [isUploadProfileLoading])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)

		if (address === profile?.city['ru']) {
			tg.MainButton.setParams({
				color: '#F1BBBD',
				is_visible: true,
				is_active: false,
				text: 'Сохранить'
			})
		} else {
			tg.MainButton.setParams({
				color: !!address ? '#EF5652' : '#F1BBBD',
				is_visible: true,
				is_active: !!address,
				text: 'Сохранить'
			})
		}

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [address])

	return (
		<ButtonLayout>
			<form className='flex flex-col items-center justify-start gap-3'>
				<Typography variant='h3' className='w-full leading-[28px]'>
					Изменение профиля
				</Typography>
				<Typography
					variant='text-mini'
					className='w-full text-black leading-[18px] tracking-[0.3px]'
				>
					Укажи город — это поможет найти подруг поблизости.
				</Typography>
				<Field
					control={control}
					onChange={e => {
						if (ADDRESS_REGEX.test(e.target.value)) {
							clearErrors('address')
							setValue('address', e.target.value)
						} else {
							tg.HapticFeedback.notificationOccurred('warning')
							setError('address', {})
							setTimeout(() => {
								clearErrors('address')
							}, 250)
						}
					}}
					name='address'
					type='text'
					placeholder='Город'
				/>
			</form>
		</ButtonLayout>
	)
}

export default EditAddress
