import { FC, useEffect, useState } from 'react'

import { CardEnd, CardSkeleton, MainLayout, Slider } from '@/components/ui'
import { ICard } from '@/components/ui/card/card.interface'

import { useSearch } from '@/hooks'

const Community: FC = () => {
	const [isFirstCardLoading, setIsFirstCardLoading] = useState(false)
	const [cards, setCards] = useState<ICard[]>([])
	const { getData, data, isError } = useSearch()

	useEffect(() => {
		getData('community')
	}, [])

	useEffect(() => {
		setCards(data?.response)
		if (data && !data.response.length) setIsFirstCardLoading(true)
	}, [data])

	if (isError) {
		return (
			<MainLayout>
				<CardEnd type='community' />
			</MainLayout>
		)
	}

	return (
		<MainLayout>
			{!isFirstCardLoading && (
				<div className='absolute top-0 bottom-0 right-0 left-0 z-50'>
					<CardSkeleton />
				</div>
			)}
			{!data ? (
				<CardSkeleton />
			) : (
				<Slider
					setIsFirstCardLoading={setIsFirstCardLoading}
					isFirstCardLoading={isFirstCardLoading}
					cards={cards}
					setCards={setCards}
					searchId={data?.id}
				/>
			)}
		</MainLayout>
	)
}

export default Community
