import { FC, useEffect } from 'react'

import { WelcomeScreen } from '@/components/ui'

import { useTelegram } from '@/hooks'

const Welcome: FC = () => {
	const { tg } = useTelegram()

	const onSubmit = () => {
		tg.openTelegramLink('https://t.me/frienda_cc_bot')
		tg.close()
	}

	useEffect(() => {
		tg.BackButton.hide()
		tg.MainButton.setParams({
			is_visible: true,
			color: '#fff',
			text_color: '#EF3B35',
			is_active: true,
			text: 'Написать в поддержку'
		})
		tg.MainButton.onClick(onSubmit)
		// @ts-ignore
		tg?.setBottomBarColor('#EF3B35')
		tg.setBackgroundColor('#EF3B35')

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [])

	return <WelcomeScreen title='Сервис временно недоступен' />
}

export default Welcome
