import { FC, PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ButtonLayout, Typography } from '@/components/ui'

import { useTelegram } from '@/hooks'

interface IProps {
	title: string
	description: string
}

const EditMediaLayout: FC<PropsWithChildren<IProps>> = ({
	children,
	title,
	description
}) => {
	const { tg } = useTelegram()
	const { pathname } = useLocation()

	useEffect(() => {
		tg.MainButton.show()
	}, [])

	const getProgressBars = () => {
		switch (pathname) {
			case '/settings/edit-media/beautiful':
				return 1
			case '/settings/edit-media/professional':
				return 2
			case '/settings/edit-media/authentic':
				return 3
		}
	}

	return (
		<ButtonLayout>
			<div className='flex flex-col gap-3 h-full overflow-y-hidden'>
				<div className='pb-1.5 mt-[3px]'>
					<div className='relative rounded-sm overflow-hidden w-full h-[3px] bg-gray'>
						<div
							className='absolute h-full bg-accent'
							style={{ width: `${(getProgressBars() / 3) * 100}%` }}
						/>
					</div>
				</div>
				<Typography variant='h3' className='w-full leading-7'>
					{title}
				</Typography>
				<Typography
					variant='text-mini'
					className='w-full text-black leading-[18px] tracking-[0.3px]'
				>
					{description}
				</Typography>
				{children}
			</div>
		</ButtonLayout>
	)
}

export default EditMediaLayout
