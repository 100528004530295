import { useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useAmplitude, useProfile } from '@/hooks'
import {
	maleUserRoutes,
	registrationRoutes,
	serverErrorRoutes,
	subscriptionRenewalRoutes,
	subscriptionWaitRoutes,
	updateRoutes,
	userRoutes
} from '@/navigation/routes'

export const useNavigateRoutes = () => {
	const { isInitProfileError, isInitProfileSuccess, errorInitProfile } =
		useProfile(true)
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const { setProperties } = useAmplitude()
	let throwUrl = ''
	const errorPayment = queryParams.get('error')
	if (errorPayment) localStorage.setItem('errorPayment', errorPayment)
	if (location.pathname === '/start') localStorage.setItem('isStart', 'true')
	const previousRoutesRef = useRef([])

	const routes = useMemo(() => {
		let newRoutes = previousRoutesRef.current

		if (isInitProfileSuccess) {
			if (JSON.parse(localStorage.getItem('isStart'))) {
				throwUrl = '/start'
			} else {
				throwUrl = '/community'
			}
			setProperties('account', 'created')
			setProperties('subscription', 'active')
			newRoutes = userRoutes
		}

		if (isInitProfileError) {
			switch (errorInitProfile?.response?.data?.error) {
				case 'registration_flow': {
					const data = errorInitProfile.response.data.data

					localStorage.setItem('register', 'false')
					localStorage.setItem('isPayment', 'false')

					if (errorInitProfile.response.data.payment) {
						localStorage.setItem('isPayment', 'true')
						localStorage.setItem('isStart', 'true')
					}

					if (errorInitProfile.response.data.welcome) {
						throwUrl = '/welcome'
						newRoutes = registrationRoutes
					} else {
						if (!data.birthday) {
							throwUrl = '/auth/date'
						} else if (!data.city) {
							throwUrl = '/auth/address'
						} else if (!data.email) {
							throwUrl = '/auth/contacts'
						} else if (!data.emoji) {
							throwUrl = '/auth/emoji'
						} else if (!data.media) {
							throwUrl = '/auth/beautiful/photo'
						} else if (!data.description) {
							throwUrl = '/auth/beautiful/description'
						} else if (!data.media.professional) {
							throwUrl = '/auth/professional/photo'
						} else if (!data.description.professional) {
							throwUrl = '/auth/professional/description'
						} else if (!data.media.authentic) {
							throwUrl = '/auth/authentic/photo'
						} else if (!data.description.authentic) {
							throwUrl = '/auth/authentic/description'
						} else if (!errorInitProfile.response.data.liveness) {
							throwUrl = '/auth/face-liveness/start'
						} else if (!errorInitProfile.response.data.payment) {
							localStorage.setItem('register', 'true')
							if (errorInitProfile.response.data.expired) {
								localStorage.setItem('errorPayment', 'true')
							}
							throwUrl = '/auth/payment'
						}

						setProperties('account', 'registration')
						newRoutes = registrationRoutes
					}
					break
				}
				case 'subscription_renewal_flow': {
					throwUrl = '/welcome'
					newRoutes = subscriptionRenewalRoutes
					setProperties('account', 'created')
					setProperties('subscription', 'expired')
					break
				}
				case 'male_user_flow': {
					throwUrl = '/welcome'
					newRoutes = maleUserRoutes
					break
				}
				case 'data_update_flow_v1_v2': {
					const data = errorInitProfile.response.data.data

					if (errorInitProfile.response.data.payment) {
						localStorage.setItem('isPayment', 'true')
					}
					if (errorInitProfile.response.data.welcome) {
						throwUrl = '/welcome'
					} else if (!data.birthday) {
						throwUrl = '/auth/date'
					} else if (!data.email) {
						throwUrl = '/auth/contacts'
					} else if (!data.media) {
						throwUrl = '/auth/beautiful/photo'
					} else if (!data.description) {
						throwUrl = '/auth/beautiful/description'
					} else if (!data.media.professional) {
						throwUrl = '/auth/professional/photo'
					} else if (!data.description.professional) {
						throwUrl = '/auth/professional/description'
					} else if (!data.media.authentic) {
						throwUrl = '/auth/authentic/photo'
					} else if (!data.description.authentic) {
						throwUrl = '/auth/authentic/description'
					} else if (!errorInitProfile.response.data.liveness) {
						throwUrl = '/auth/face-liveness/start'
					} else if (!errorInitProfile.response.data.payment) {
						localStorage.setItem('register', 'true')
						throwUrl = '/auth/payment'
					}

					newRoutes = updateRoutes
					setProperties('account', 'updating')
					break
				}
				case 'subscription_wait_flow': {
					throwUrl = '/loading'
					newRoutes = subscriptionWaitRoutes

					break
				}
				default: {
					throwUrl = '/welcome'
					newRoutes = serverErrorRoutes
				}
			}
		}

		previousRoutesRef.current = newRoutes
		return newRoutes
	}, [isInitProfileError, isInitProfileSuccess])

	return {
		routes,
		throwUrl
	}
}
