import {
	Certificate,
	Community,
	EditAddress,
	EditAuthenticDescription,
	EditAuthenticPhoto,
	EditBeautifulDescription,
	EditBeautifulPhoto,
	EditContacts,
	EditEmoji,
	EditProfessionalDescription,
	EditProfessionalPhoto,
	Friend,
	Friends,
	Profile,
	Settings,
	Start
} from '@/components/screens/authorized'

import { IRoute } from '../navigation.types'

export const userRoutes: IRoute[] = [
	{
		path: '/start',
		component: Start
	},
	{
		path: '/community',
		component: Community
	},
	{
		path: '/profile',
		component: Profile
	},
	{
		path: '/friends',
		component: Friends
	},
	{
		path: '/friend',
		component: Friend
	},
	{
		path: '/settings',
		component: Settings
	},
	{
		path: '/settings/certificate',
		component: Certificate
	},
	{
		path: '/settings/edit-emoji',
		component: EditEmoji
	},
	{
		path: '/settings/edit-address',
		component: EditAddress
	},
	{
		path: '/settings/edit-contacts',
		component: EditContacts
	},
	{
		path: '/settings/edit-media/beautiful',
		component: EditBeautifulPhoto
	},
	{
		path: '/settings/edit-media/professional',
		component: EditProfessionalPhoto
	},
	{
		path: '/settings/edit-media/authentic',
		component: EditAuthenticPhoto
	},
	{
		path: '/settings/edit-description/beautiful',
		component: EditBeautifulDescription
	},
	{
		path: '/settings/edit-description/professional',
		component: EditProfessionalDescription
	},
	{
		path: '/settings/edit-description/authentic',
		component: EditAuthenticDescription
	}
]

export default userRoutes
