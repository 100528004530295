import cn from 'clsx'
import { ChangeEventHandler, FC, useRef } from 'react'

import { IFileField } from './file-field.interface'

const FileField: FC<IFileField> = ({
	onChange,
	className,
	value,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null)

	const handleFile: ChangeEventHandler<HTMLInputElement> = e => {
		const files = e.target.files
		if (files[0].type.startsWith('image/') && files[0].size < 15728640) {
			onChange(files)
		}
		if (files[0].type.startsWith('video/') && files[0].size < 50428800) {
			onChange(files)
		}
	}

	return (
		<input
			type='file'
			ref={inputRef}
			onChange={handleFile}
			className={cn('opacity-0 absolute top-0 left-0 w-full h-full', {
				'cursor-pointer': !props.disabled
			})}
			{...props}
		/>
	)
}

export default FileField
