import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SettingsScreen } from '@/components/ui'

import { useTelegram } from '@/hooks'

const Settings: FC = () => {
	const navigate = useNavigate()
	const { tg } = useTelegram()

	const onBack = () => {
		navigate('/profile')
	}

	useEffect(() => {
		tg.BackButton.onClick(onBack)

		return () => {
			tg.BackButton.offClick(onBack)
		}
	}, [])

	return <SettingsScreen />
}

export default Settings
