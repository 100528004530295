import cn from 'clsx'
import { FC } from 'react'

import { useTelegram } from '@/hooks'

import MenuItem from './MenuItem'
import { menuItems } from './menu.data'

const BottomMenu: FC = () => {
	const { tg } = useTelegram()

	return (
		<div
			className={cn(
				'h-full flex flex-row justify-between items-center w-full bg-white border-t-[0.5px] border-[rgba(60,60,67,0.36)] pt-0.5',
				tg.platform === 'ios' ? 'pb-[22px]' : 'pb-[7px]'
			)}
		>
			{menuItems.map(item => (
				<MenuItem key={item.iconName} {...item} />
			))}
		</div>
	)
}

export default BottomMenu
