import { FC, useEffect, useState } from 'react'

import { BottomSheet, Typography, WelcomeScreen } from '@/components/ui'

import { useTelegram } from '@/hooks'

const Welcome: FC = () => {
	const { tg } = useTelegram()
	const [showModal, setShowModal] = useState(false)

	const onSubmit = () => {
		tg.HapticFeedback.notificationOccurred('warning')
		setShowModal(true)
	}

	useEffect(() => {
		tg.SettingsButton.hide()
		tg.BackButton.hide()
		tg.MainButton.hideProgress()
		tg.MainButton.setParams({
			is_visible: true,
			color: '#fff',
			text_color: '#EF3B35',
			is_active: true,
			text: 'Лист ожидания'
		})
		// @ts-ignore
		tg?.setBottomBarColor('#EF3B35')
		tg.setBackgroundColor('#EF3B35')

		tg.MainButton.onClick(onSubmit)

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [])

	useEffect(() => {
		if (showModal) {
			tg.MainButton.hide()
		} else {
			tg.MainButton.show()
		}
	}, [showModal])

	return (
		<>
			<WelcomeScreen />
			{showModal && <div className='mt-[70px]' />}
			<BottomSheet
				isOpen={showModal}
				onClose={() => setShowModal(!showModal)}
				button='Написать в поддержку'
				onSubmit={() => {
					tg.openTelegramLink('https://t.me/frienda_cc_bot')
					tg.close()
				}}
			>
				<div className='flex flex-col gap-2 text-center items-center'>
					<Typography variant='h3' className='leading-7'>
						Ты добавлен в лист ожидания!
					</Typography>
					<Typography
						variant='text'
						className='leading-[22px] tracking-[0.2px]'
					>
						Есть вопросы — <br /> обращайся в поддержку
					</Typography>
				</div>
			</BottomSheet>
		</>
	)
}

export default Welcome
