import { FC, useEffect } from 'react'

import { Typography } from '@/components/ui'

import { useAmplitude, useProfile } from '@/hooks'

const Loading: FC = () => {
	const { refetchInitProfile, isInitProfileError, isInitProfileLoading } =
		useProfile(true)
	const { event } = useAmplitude()

	useEffect(() => {
		event('payment_pending')
		localStorage.setItem('isStart', 'true')
		if (isInitProfileError) {
			setTimeout(() => {
				refetchInitProfile()
			}, 3000)
		}
	}, [isInitProfileLoading])

	return (
		<div className='bg-red h-[100vh] flex flex-col items-center justify-center gap-2'>
			<div className='loader --large' />
			<Typography variant='text-bold' className='text-white text-center'>
				Ожидаем подтверждение <br /> оплаты
			</Typography>
		</div>
	)
}

export default Loading
