import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { ChangeEvent, FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Field, Typography } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { EMAIL_REGEX, INSTAGRAM_REGEX } from '@/utils/regex'
import { notifyError } from '@/utils/toastNotifications'

import AuthLayout from '../AuthLayout'

const Contacts: FC = () => {
	const { tg, user } = useTelegram()
	const navigate = useNavigate()
	const profile = useAtomValue(profileAtom)
	const { event, getTimeSpent, setProperties } = useAmplitude()
	const {
		uploadProfile,
		isUploadProfileSuccess,
		isUploadProfileError,
		isUploadProfileLoading
	} = useProfile()
	const { control, watch, setValue, setError, clearErrors } = useForm<{
		telegram: string
		instagram: string
		email: string
	}>({
		mode: 'onChange',
		defaultValues: {
			email: profile.email || '',
			telegram: `t.me/${user.username || profile.telegram || ''}`,
			instagram: profile?.instagram
				? `instagram.com/${profile?.instagram}`
				: 'instagram.com/'
		}
	})
	const { telegram, instagram, email } = watch()

	useEffect(() => {
		if (!user.username) {
			setError('telegram', {
				type: 'required',
				message:
					'Установи имя пользователя в Telegram или нажми Продолжить и дай доступ к номеру телефона.'
			})
		}
	}, [])

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()

		if (!user.username && !telegram.slice(5)) {
			setError('telegram', {
				type: 'required',
				message:
					'Установи имя пользователя в Telegram или нажми Продолжить и дай доступ к номеру телефона.'
			})
			tg.requestContact((success, response) => {
				event('phone_requested')

				if (success) {
					// @ts-ignore
					const phone_number = response.responseUnsafe.contact.phone_number
					setValue('telegram', `t.me/+${phone_number}`)
					tg.MainButton.showProgress()
					tg.MainButton.setParams({
						color: '#F1BBBD',
						is_active: false,
						text: 'Загрузка'
					})
					uploadProfile({
						telegram: `+${phone_number}`,
						instagram: instagram.slice(14),
						email: email
					})
					setProperties('username', `+${phone_number}`)
					event('phone_sended')
				} else {
					event('phone_canceled')
					setError('telegram', {
						message:
							'Установи имя пользователя в Telegram или нажми Продолжить и дай доступ к номеру телефона.'
					})
				}
			})
		} else {
			tg.MainButton.showProgress()
			tg.MainButton.setParams({
				color: '#F1BBBD',
				is_active: false,
				text: 'Загрузка'
			})
			uploadProfile({
				telegram: user.username ? user.username : telegram.slice(5),
				instagram: instagram.slice(14),
				email: email
			})
		}
	}

	useEffect(() => {
		if (isUploadProfileSuccess) {
			navigate('/auth/emoji')
			tg.HapticFeedback.notificationOccurred('success')
			if (instagram.slice(14)) setProperties('instagram', instagram.slice(14))
			setProperties('email', email)
			const time = getTimeSpent()
			event('social_media_entered', {
				is_instagram_added: !!instagram.slice(14),
				duration: time,
				flow: 'updating'
			})
		}
		if (isUploadProfileError) {
			tg.MainButton.hideProgress()
			notifyError('Указан некорректный email.')
			setError('email', {})
			tg.MainButton.setParams({
				color: '#EF5652',
				is_visible: true,
				is_active: true,
				text: 'Продолжить'
			})
		}
	}, [isUploadProfileLoading])

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.BackButton.onClick(() => navigate('/auth/address'))
	}, [])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)

		const disabled = Boolean(!email || !EMAIL_REGEX.test(email))

		tg.MainButton.setParams({
			color: disabled ? '#F1BBBD' : '#EF5652',
			is_visible: true,
			is_active: !disabled,
			text: 'Продолжить'
		})

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [instagram, email])

	const handleInstagramChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		const instagramPrefix = 'instagram.com/'
		const userInput = value.slice(instagramPrefix.length)
		if (INSTAGRAM_REGEX.test(userInput)) {
			clearErrors('instagram')
			setValue('instagram', instagramPrefix + userInput)
		} else {
			tg.HapticFeedback.notificationOccurred('warning')
			setError('instagram', {})
			setTimeout(() => {
				clearErrors('instagram')
			}, 250)
		}
	}

	return (
		<AuthLayout
			title='Контактные данные'
			description='На почту придут только важные письма.'
			footer={{
				icon: 'eye-fill',
				title: 'Ссылки на соцсети станут видны только после взаимного лайка.'
			}}
		>
			<Field
				control={control}
				onBlur={e => {
					if (EMAIL_REGEX.test(e.target.value)) {
						clearErrors('email')
					} else {
						tg.HapticFeedback.notificationOccurred('warning')
						notifyError('Указан некорректный email.')
						setError('email', {})
					}
				}}
				placeholder='example@gmail.com'
				inputMode='email'
				name='email'
				icon='email'
			/>
			<Typography
				variant='text-mini'
				className='w-full text-black leading-[18px] tracking-[0.3px] mb-1'
			>
				После взаимного лайка общение с подругой продолжится в Instagram и
				Telegram.
			</Typography>
			<div className='flex flex-col gap-2'>
				<Field
					control={control}
					className={cn(instagram === 'instagram.com/' ? 'text-dark-gray' : '')}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							const target = e.target as HTMLInputElement
							target.blur()
						}
					}}
					onClick={event => {
						const instagramPrefixLength = 'instagram.com/'.length
						// @ts-ignore
						if (event.target.selectionStart < instagramPrefixLength) {
							// @ts-ignore
							event.target.setSelectionRange(instagram.length, instagram.length)
						}
					}}
					onChange={handleInstagramChange}
					placeholder='instagram.com/'
					name='instagram'
					icon='instagram'
				/>
				<Field
					control={control}
					disabled
					name='telegram'
					icon='telegram'
					onClick={() => {
						if (user.username) return
						tg.openTelegramLink('tg://settings/edit_profile')
					}}
				/>
			</div>
		</AuthLayout>
	)
}

export default Contacts
