import { useMutation } from '@tanstack/react-query'
import { useMemo } from 'react'

import { SearchService } from '@/services/search.service'

import { useTelegram } from '@/hooks'

export const useSearch = () => {
	const { user } = useTelegram()

	const {
		mutate: getData,
		data,
		isIdle,
		isLoading,
		isError
	} = useMutation(['search'], (view: string) =>
		SearchService.search({ user_id: user.id, view })
	)

	return useMemo(
		() => ({
			getData,
			data,
			isLoading,
			isIdle,
			isError
		}),
		[isLoading, data]
	)
}
