import axios from 'axios'

import { API_URL } from '@/config/api.config'

const instance = axios.create({
	baseURL: API_URL
})

instance.interceptors.request.use(async config => {
	config.headers.Authorization =
		'Bearer ee90b0f709b46840fbec0b9927f5845f479df6072a451b89ce0eb7021d5a2c58'
	config.headers['X-Init-Data'] = window.Telegram.WebApp.initData

	return config
})

export default instance
