import { TVariant } from './typography.interface'

export const config: Record<TVariant, string> = {
	button: 'text-[17px] font-bold',
	text: 'text-[17px] font-normal',
	h3: 'text-[22px] font-bold',
	h2: 'text-[28px] font-bold',
	menu: 'text-[10px] font-medium',
	'text-small': 'text-[13px] font-normal',
	'text-small-bold': 'text-[13px] font-bold',
	'text-mini': 'text-[15px] font-normal',
	'text-bold': 'text-[17px] font-bold'
}
