import cn from 'clsx'
import { useAtomValue } from 'jotai'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ButtonLayout, Field, Typography } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { EMAIL_REGEX, INSTAGRAM_REGEX } from '@/utils/regex'
import { notifyError } from '@/utils/toastNotifications'

const EditContacts: FC = () => {
	const navigate = useNavigate()
	const profile = useAtomValue(profileAtom)
	const { event, setProperties, getTimeSpent } = useAmplitude()
	const {
		uploadProfile,
		isUploadProfileLoading,
		isUploadProfileSuccess,
		isUploadProfileError
	} = useProfile()
	const { tg } = useTelegram()
	const [data, setData] = useState({
		telegram: profile?.telegram,
		instagram: profile?.instagram,
		email: profile?.email
	})

	const { control, watch, setValue, setError, clearErrors } = useForm({
		defaultValues: {
			telegram: `t.me/${profile.telegram || profile.user_id}`,
			email: data.email,
			instagram: data.instagram
				? `instagram.com/${data.instagram}`
				: 'instagram.com/'
		},
		mode: 'onChange'
	})
	const { instagram, telegram, email } = watch()

	useEffect(() => {
		tg.MainButton.hideProgress()
		tg.BackButton.onClick(() => navigate('/settings'))

		return () => {
			tg.MainButton.hide()
		}
	}, [])

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_visible: true,
			is_active: false,
			text: 'Загрузка'
		})
		uploadProfile(data)
	}

	useEffect(() => {
		if (isUploadProfileSuccess) {
			tg.MainButton.hide()
			tg.HapticFeedback.notificationOccurred('success')
			if (instagram.slice(14)) setProperties('instagram', instagram.slice(14))
			setProperties('email', email)
			const time = getTimeSpent()
			event('social_media_entered', {
				is_instagram_added: !!instagram.slice(14),
				duration: time,
				flow: 'settings'
			})
			navigate('/settings')
		}
		if (isUploadProfileError) {
			tg.MainButton.hideProgress()
			notifyError('Указан некорректный email.')
			setError('email', {})
			tg.MainButton.setParams({
				color: '#EF5652',
				is_visible: true,
				is_active: true,
				text: 'Продолжить'
			})
		}
	}, [isUploadProfileLoading])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)

		if (
			JSON.stringify(data) ===
			JSON.stringify({
				telegram: profile?.telegram,
				instagram: profile?.instagram,
				email: profile?.email
			})
		) {
			tg.MainButton.setParams({
				color: '#F1BBBD',
				is_visible: true,
				is_active: false,
				text: 'Сохранить'
			})
		} else {
			tg.MainButton.setParams({
				color: '#EF5652',
				is_visible: true,
				is_active: true,
				text: 'Сохранить'
			})
		}

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [data])

	useEffect(() => {
		setData({
			telegram: telegram.slice(5),
			instagram: instagram.slice(14),
			email: EMAIL_REGEX.test(email) ? email : data.email
		})
	}, [instagram, email])

	const handleInstagramChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		const instagramPrefix = 'instagram.com/'
		const userInput = value.slice(instagramPrefix.length)
		if (INSTAGRAM_REGEX.test(userInput)) {
			clearErrors('instagram')
			setValue('instagram', instagramPrefix + userInput)
		} else {
			tg.HapticFeedback.notificationOccurred('warning')
			setError('instagram', {})
			setTimeout(() => {
				clearErrors('instagram')
			}, 250)
		}
	}

	return (
		<ButtonLayout>
			<form className='flex flex-col items-center justify-start gap-3'>
				<Typography variant='h3' className='w-full leading-7'>
					Контактные данные
				</Typography>
				<Typography
					variant='text-mini'
					className='w-full text-black leading-[18px] tracking-[0.3px]'
				>
					На почту придут только важные письма.
				</Typography>
				<Field
					control={control}
					onBlur={e => {
						if (EMAIL_REGEX.test(e.target.value)) {
							clearErrors('email')
						} else {
							tg.HapticFeedback.notificationOccurred('warning')
							notifyError('Указан некорректный email.')
							setError('email', {})
						}
					}}
					inputMode='email'
					placeholder='example@gmail.com'
					name='email'
					icon='email'
				/>
				<Typography
					variant='text-mini'
					className='w-full text-black leading-[18px] tracking-[0.3px] mb-1'
				>
					После взаимного лайка вы с подругой перейдёте к общению в Instagram и
					Telegram.
				</Typography>
				<div className='flex flex-col gap-2 w-full'>
					<Field
						control={control}
						className={cn(
							instagram === 'instagram.com/' ? 'text-dark-gray' : ''
						)}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								const target = e.target as HTMLInputElement
								target.blur()
							}
						}}
						onClick={event => {
							const instagramPrefixLength = 'instagram.com/'.length
							// @ts-ignore
							if (event.target.selectionStart < instagramPrefixLength) {
								// @ts-ignore
								event.target.setSelectionRange(
									instagram.length,
									instagram.length
								)
							}
						}}
						onChange={handleInstagramChange}
						placeholder='instagram.com/'
						name='instagram'
						icon='instagram'
					/>
					<Field control={control} disabled name='telegram' icon='telegram' />
				</div>
			</form>
		</ButtonLayout>
	)
}

export default EditContacts
