import { useAtomValue } from 'jotai/index'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Field } from '@/components/ui'

import { useAmplitude, useProfile, useTelegram } from '@/hooks'
import { profileAtom } from '@/store'
import { NAME_REGEX } from '@/utils/regex'

import AuthLayout from '../AuthLayout'

const Name: FC = () => {
	const { tg } = useTelegram()
	const profile = useAtomValue(profileAtom)
	const { event, getTimeSpent } = useAmplitude()
	const navigate = useNavigate()
	const {
		uploadProfile,
		isUploadProfileSuccess,
		isUploadProfileError,
		isUploadProfileLoading
	} = useProfile()
	const { control, watch, setValue, setError, clearErrors } = useForm<{
		name: string
	}>({
		mode: 'onChange',
		defaultValues: {
			name: profile?.name || ''
		}
	})
	const { name } = watch()

	const onSubmit = () => {
		const activeElement = document.activeElement as HTMLElement
		activeElement?.blur()
		tg.MainButton.showProgress()
		tg.MainButton.setParams({
			color: '#F1BBBD',
			is_active: false,
			text: 'Загрузка'
		})
		uploadProfile({ name })
	}

	useEffect(() => {
		if (isUploadProfileSuccess) {
			const time = getTimeSpent()
			const isEdited = profile?.name ? name !== profile?.name : false
			event('name_entered', {
				duration: time,
				prefill: !!profile?.name,
				edited: isEdited,
				flow: 'registration'
			})
			navigate('/auth/date')
			tg.HapticFeedback.notificationOccurred('success')
		}
		if (isUploadProfileError) {
			tg.MainButton.hideProgress()
			setError('name', {})
			tg.MainButton.setParams({
				color: '#EF5652',
				is_visible: true,
				is_active: true,
				text: 'Продолжить'
			})
		}
	}, [isUploadProfileLoading])

	useEffect(() => {
		tg.BackButton.onClick(() => navigate('/welcome'))
	}, [])

	useEffect(() => {
		tg.MainButton.onClick(onSubmit)
		const words = name.split(' ')

		const isValid =
			words.length === 2 &&
			words.every(word => word.trim() !== '' && word.length >= 2)

		const disabled = Boolean(!!name && isValid)

		tg.MainButton.setParams({
			color: disabled ? '#EF5652' : '#F1BBBD',
			is_visible: true,
			is_active: disabled,
			text: 'Продолжить'
		})

		return () => {
			tg.MainButton.offClick(onSubmit)
		}
	}, [name])

	return (
		<AuthLayout
			title='Как тебя зовут?'
			footer={{
				icon: 'warning-round',
				title: 'Изменить имя и фамилию после регистрации нельзя.'
			}}
		>
			<Field
				onKeyDown={e => {
					if (e.key === 'Enter') {
						const target = e.target as HTMLInputElement
						target.blur()
					}
				}}
				control={control}
				onChange={e => {
					if (NAME_REGEX.test(e.target.value)) {
						clearErrors('name')
						setValue('name', e.target.value)
					} else {
						tg.HapticFeedback.notificationOccurred('warning')
						setError('name', {})
						setTimeout(() => {
							clearErrors('name')
						}, 250)
					}
				}}
				name='name'
				type='text'
				placeholder='Имя Фамилия'
			/>
		</AuthLayout>
	)
}

export default Name
