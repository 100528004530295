import forge from 'node-forge'

import { ENCRYPTION_KEY } from '@/config/api.config'

export const encryptionField = (token: string | number) => {
	if (!token) return ''

	const key = forge.util.createBuffer(ENCRYPTION_KEY, 'utf8')
	const iv = forge.util.decode64(token.toString().slice(0, 24))
	const ct = forge.util.decode64(token.toString().slice(24, -24))
	const tag = forge.util.decode64(token.toString().slice(-24))

	const decipher = forge.cipher.createDecipher('AES-GCM', key)
	decipher.start({ iv: iv, tag: forge.util.createBuffer(tag) })
	decipher.update(forge.util.createBuffer(ct))
	const pass = decipher.finish()

	return pass ? decipher.output.toString() : ''
}
